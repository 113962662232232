import { checkLuhn } from '@bl/functions';

// -------------------
// This social security number validation will be moved to bl/functions
// -------------------
const matchFullDate = (date: string) => !!date?.match(/^(\d{4})(\d{2})(\d{2})$/);
const matchShortDate = (date: string) => !!date?.match(/^(\d{2})(\d{2})(\d{2})$/);
const formatDateWithDash = (date: string) => date?.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
const removeDashes = (date: string) => date?.replace(/-/g, '');

// add century to short date and add 19 to year if it is greater than current year otherwise add 20
const addCenturyToShortDate = (date: string) => {
  const year = date.slice(0, 2);
  const currentYear = new Date().getFullYear().toString().slice(2, 4);
  if (year > currentYear) {
    return `19${date}`;
  }
  return `20${date}`;
};

const formatDate = (date: string): string | null => {
  if (matchFullDate(date)) {
    return formatDateWithDash(date);
  }
  if (matchShortDate(date)) {
    return formatDateWithDash(addCenturyToShortDate(date));
  }
  return null;
};

const validateLength = (socialSecurityNumber: string) =>
  socialSecurityNumber?.length === 10 || socialSecurityNumber?.length === 12;

// if the social security number is 12 digits, the first two digits are the century and should be removed
const getLuhnValue = (socialSecurityNumber: string) => {
  const startPosition = socialSecurityNumber.length === 12 ? 2 : 0;
  return socialSecurityNumber.slice(startPosition);
};

const validateDateOfBirth = (socialSecurityNumber: string) => {
  // get the "probable" end position of the dateOfBirth
  const endPosition = socialSecurityNumber.length === 12 ? 8 : 6;
  const formattedDate = formatDate(socialSecurityNumber.slice(0, endPosition));

  const date = new Date(formattedDate ?? '');
  const currentDate = new Date();
  const minDate = new Date('1900-01-01');
  return date < currentDate && date > minDate; // is dateOfBirth between 1900-01-01 and today
};

const validateSocialSecurityNumber = (socialSecurityNumber: string) => {
  const socialSecurityNumberWithoutDash = removeDashes(socialSecurityNumber);

  return (
    validateLength(socialSecurityNumberWithoutDash) &&
    checkLuhn(getLuhnValue(socialSecurityNumberWithoutDash)) &&
    validateDateOfBirth(socialSecurityNumberWithoutDash)
  );
};

// formats the social security number with century and into format YYYYMMDDXXXX
const formatSocialSecurityNumberWithCentry = (socialSecurityNumber: string): string | null => {
  const socialSecurityNumberWithoutDash = removeDashes(socialSecurityNumber);

  if (socialSecurityNumberWithoutDash?.length === 10) {
    return addCenturyToShortDate(socialSecurityNumberWithoutDash);
  }
  return socialSecurityNumberWithoutDash || null;
};

// -------------------

const MAX_ARCHIVE_YEARS = 10;

const validateYears = (years: number): boolean => years > 0 && years <= MAX_ARCHIVE_YEARS;

export { validateSocialSecurityNumber, validateYears, formatSocialSecurityNumberWithCentry };
