import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { FinancialYear } from '../entity/financialyear';
import { BLService } from './bl.service';
import { HttpAuthorizationHeader } from '../misc/httpauthorizationheader';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from '../state/appState';

@Injectable()
export class FinancialYearService extends BLService {
  private options = new HttpAuthorizationHeader('application/json');
  private RESOURCE_ENDPOINT = 'financialyear';
  private url = environment.serverUrl + this.RESOURCE_ENDPOINT;

  constructor(private http: HttpClient, store: Store<AppState>) {
    super(store);
  }

  getFinancialYearsForClientId(id: number): Observable<FinancialYear[]> {
    return this.http
      .get<FinancialYear[]>(`${this.url}/reverse/${id}`, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(this.catchErrorAndShowMessage());
  }

  saveFinancialYear(financialYear: FinancialYear): Observable<FinancialYear> {
    const body = JSON.stringify(financialYear);

    return this.http
      .put<FinancialYear>(this.url, body, this.options.getAuthorizationHeader())
      .pipe(this.catchErrorAndShowMessage());
  }

  addNextFinancialYear(clientId: number): Observable<FinancialYear> {
    return this.http
      .put<FinancialYear>(`${this.url}/next/${clientId}`, '', this.options.getAuthorizationHeader())
      .pipe(this.catchErrorAndShowMessage());
  }

  deleteFinancialYearWithId(financialYearIdid: number): Observable<FinancialYear[]> {
    return this.http
      .delete<FinancialYear[]>(`${this.url}/${financialYearIdid}`, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(this.catchErrorAndShowMessage());
  }
}
