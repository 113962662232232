import { Injectable } from '@angular/core';
import { BLService } from './bl.service';
import { HttpAuthorizationHeader } from '../misc/httpauthorizationheader';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppState } from '../state/appState';
import { Observable, catchError, map, throwError } from 'rxjs';
import {
  OnboardCompanyWithMiniCollaborationPackageBody,
  OnboardCompanyWithMiniCollaborationPackageResponse,
  OnboardExistingClientBody,
  OnboardingExtraClientInfo,
  OnboardingResponse,
} from './onboarding.service.types';
import { ToastActions } from '../state/toast/toast.actions';

@Injectable({ providedIn: 'root' })
export class OnboardingService extends BLService {
  private options = new HttpAuthorizationHeader('application/json');
  private RESOURCE_ENDPOINT = 'onboarding';
  private url = environment.serverUrl + this.RESOURCE_ENDPOINT;

  constructor(private http: HttpClient, store: Store<AppState>) {
    super(store);
  }

  getOnboardingKey(clientId: number, body: OnboardExistingClientBody): Observable<string> {
    return this.http
      .post<OnboardingResponse>(`${this.url}/${clientId}`, body, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(
        map((response: OnboardingResponse) => response.key),
        catchError((err: unknown) => {
          this.dispatchOnboardingError();
          return throwError(() => err);
        }),
        this.catchErrorAndShowMessage(),
      );
  }

  getOnboardingKeyForNonExistingClient(body: OnboardingExtraClientInfo): Observable<string> {
    return this.http
      .post<OnboardingResponse>(this.url, { client: body }, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(
        map((response: OnboardingResponse) => response.key),
        this.catchErrorAndShowMessage(),
      );
  }

  onboardCompanyWithMiniCollaborationPackage(
    body: OnboardCompanyWithMiniCollaborationPackageBody,
  ): Observable<OnboardCompanyWithMiniCollaborationPackageResponse> {
    return this.http
      .post<OnboardCompanyWithMiniCollaborationPackageResponse>(
        `${this.url}/company/with-non-email-user`,
        body,
        this.options.getAuthorizationHeaderWithEmptyBody(),
      )
      .pipe(this.catchErrorAndShowMessage());
  }

  private dispatchOnboardingError(): void {
    this.store.dispatch(
      ToastActions.showErrorMessage({
        summary: 'Ett fel uppstod',
        detail:
          'Något gick fel när information om klienten skulle hämtas för att komma vidare till onboardingen. Kontrollera uppgifterna på klienten och försök igen.',
      }),
    );
  }
}
