export const errors: Record<string, string> = {
  ACCESSING_CLOUD_DATABASE_FAILED: 'Det gick inte att hämta information om/från molndatabasen.',
  FAILED_TO_LOAD_SUBSCRIPTION: 'Det gick inte att hämta paketsinformation.',
  ARCHIVE_CLOUD_COMPANY_FAILED: 'Det gick inte att arkivera företaget/molndatabasen.',
  ARCHIVE_INVALID_AMOUNT_OF_YEARS:
    'Du måste ange antal år mellan 1 och 10 som du vill arkivera företaget/molndatabasen.',
  DELETE_CLOUD_COMPANY_FAILED: 'Det gick inte att ta bort företaget/molndatabasen.',
  UNARCHIVE_MISSING_CLIENT_NAME: 'Återaktiveringen misslyckades för det saknas namn på klienten.',
  UNARCHIVE_MISSING_SOCIAL_SECURITY_NUMBER: 'Du måste ange ett giltigt personnummer för att påbörja återaktiveringen.',
  UNARCHIVE_CLOUD_COMPANY_FAILED: 'Det gick inte att återaktivera företaget/molndatabasen. Kontakta supporten.',
  FAILED_TO_CREATE_COLLABORATION_PACKET: 'Det gick inte att skapa samarbetspaket.',
  MISSING_KEY_WHEN_CREATE_SMALL_PACKET: 'Viktig information saknas och samarbetspaketet kan inte skapas.',
  MISSING_VALUE_WHEN_CREATE_SMALL_PACKET: 'Viktig information saknas och samarbetspaketet kan inte skapas.',
  CREATE_SMALL_PACKET_MISSING_PAYING_CUSTOMER_NUMBER_ON_FIRM: 'Det saknas betalande kundnummer på byrån.',
  INVALID_SOCIAL_SECURITY_NUMBER: 'Personnumret är ogiltigt.',
};
