<app-table #table storageKey="clientListTable" [columns]="columns" [data]="clients$ | async"
  [rowsPerPage]="defaultNumberOfRows" sortField="name" exportFilename="Klientlista" (cellClick)="onCellClick($event)">
  <app-table-filter>
    <app-team-multi-selector localStorageKey="clients" (OnTeamsInit)="reloadClients()"></app-team-multi-selector>
    <app-table-filter-item label="Klientansvarig">
      <app-user-selector [(userId)]="selectedClientResponsibleUserId" styleClass="min-w-48"
        [disabled]="loading$ | async"></app-user-selector>
    </app-table-filter-item>
    <app-table-filter-item>
      <app-table-refresh-button (onClick)="reloadClients()"></app-table-refresh-button>
    </app-table-filter-item>
    <app-table-filter-item>
      <p-checkbox [binary]="true" [(ngModel)]="includeArchivedClients" (ngModelChange)="refilterClients()"
        [label]="includeArchivedLabel$ | async" [disabled]="loading$ | async"></p-checkbox>
    </app-table-filter-item>
    <app-table-filter-item containerClass="ml-12">
      <button type="button" class="btn btn-primary" [disabled]="loading$ | async" (click)="showNewClient()">
        Ny klient
      </button>
    </app-table-filter-item>
    <app-table-filter-item fill></app-table-filter-item>
    <app-table-filter-item containerClass="mr-2">
      <button type="button" class="btn btn-primary" [disabled]="loading$ | async"
        (click)="showCompareCloudCompaniesDialog()">
        Jämför mot Företag i molnet
      </button>
    </app-table-filter-item>
  </app-table-filter>

  <ng-template tableTemplate="bodyCell" tableBodyCellField="default" let-content>
    <span class="cursor-pointer hover:underline">
      {{ content }}
    </span>
  </ng-template>
  <ng-template tableTemplate="bodyCell" tableBodyCellField="advisoryTool" let-isAdvisoryTool>
    <div class="flex items-center justify-center">
      <div class="h-3 w-3 rounded-full shadow-lg" [ngClass]="isAdvisoryTool ? 'bg-bl-green-600' : 'bg-bl-red-500'">
      </div>
      <button type="button" class="min-w-32 btn btn-default ml-4" style="padding: 1px;">
        {{ isAdvisoryTool ? 'Avaktivera' : 'Aktivera' }}
      </button>
    </div>
  </ng-template>
  <ng-template tableTemplate="bodyCell" tableBodyCellField="external-app-bla" let-rowData="rowData">
    <ng-container *ngTemplateOutlet="externalBLColumn; context: { $implicit: rowData }"></ng-container>
  </ng-template>
  <ng-template tableTemplate="bodyCell" tableBodyCellField="external-app-bls-current">
    <ng-container *ngTemplateOutlet="externalAppColumn; context: { $implicit: 'BLSkatt' }"></ng-container>
  </ng-template>
  <ng-template tableTemplate="bodyCell" tableBodyCellField="external-app-bls-prev">
    <ng-container *ngTemplateOutlet="externalAppColumn; context: { $implicit: 'BLSkatt' }"></ng-container>
  </ng-template>
  <ng-template tableTemplate="bodyCell" tableBodyCellField="external-app-blb-current">
    <ng-container *ngTemplateOutlet="externalAppColumn; context: { $implicit: 'BLBokslut' }"></ng-container>
  </ng-template>
  <ng-template tableTemplate="bodyCell" tableBodyCellField="external-app-blb-prev">
    <ng-container *ngTemplateOutlet="externalAppColumn; context: { $implicit: 'BLBokslut' }"></ng-container>
  </ng-template>
  <ng-template tableTemplate="bodyCell" tableBodyCellField="action" let-rowData="rowData">
    <span *ngIf="!rowData.archived" (click)="tryDeleteClient(rowData)" class="far fa-trash-alt"
      style="text-align: center; cursor: pointer">
    </span>
    <span *ngIf="rowData.archived" (click)="reactivateClient(rowData)" class="fa fa-recycle"
      style="text-align: center; cursor: pointer">
    </span>
  </ng-template>
  <ng-template tableTemplate="emptyMessage">{{ statusText$ | async }}</ng-template>
  <ng-template tableTemplate="summary" let-data>
    <div class="relative">
      Antal klienter: {{ data.length }}
      <div class="absolute export-button-wrapper">
        <p-button icon="pi pi-download" class="export-button" size="small" label="Exportera lista"
          (click)="table.exportCSV()" title="Ladda ner listan för import till Excel"></p-button>
      </div>
    </div>
  </ng-template>
  <ng-template #externalBLColumn let-rowData>
    <div class="flex justify-center cursor-pointer">
      <app-bla-icon-button [client]="rowData"></app-bla-icon-button>
    </div>
  </ng-template>
  <ng-template #externalAppColumn let-type>
    <div class="flex justify-center cursor-pointer">
      <img [src]="type | getIcon" alt="Starta programmet" class="inline" />
    </div>
  </ng-template>
  <ng-template tableTemplate="bodyCell" tableBodyCellField="sustainabilityReporting" let-rowData="rowData">
    <div class="flex items-center justify-center">
      <div class="h-3 w-3 rounded-full shadow-lg"
        [ngClass]="rowData.sustainabilityReporting ? 'bg-bl-green-600' : 'bg-bl-red-500'"></div>
      <button type="button" class="min-w-32 btn btn-default ml-4" style="padding: 1px;"
        [disabled]="rowData.sustainabilityReportingDisabled" [pTooltip]="rowData.sustainabilityReportingTooltip">
        {{ rowData.sustainabilityReporting ? 'Avaktivera' : 'Aktivera' }}
      </button>
    </div>
  </ng-template>
</app-table>

<a href="#" id="externalAppCaller"></a>

<ng-container *ngIf="displayArchiveClientDialog">
  <app-client-archive-client-dialog [(visible)]="displayArchiveClientDialog" [name]="pendingDeletionClient?.name"
    (onConfirm)="onArchiveClient()" (onCancel)="onCancel()"></app-client-archive-client-dialog>
</ng-container>