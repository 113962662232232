import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, UrlTree } from '@angular/router';
import { Jwt } from '../misc/jwt';
import { LoginStatus } from '../interceptors/auth.interceptor';

export const jwtParamGuard: CanActivateFn = (route: ActivatedRouteSnapshot): boolean | UrlTree => {
  const router = inject(Router);
  const { jwt } = route.queryParams;

  return Jwt.isValidToken(jwt) || router.parseUrl(`/login?error=${LoginStatus.UnAuthorized}`);
};
