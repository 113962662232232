import { Injectable } from '@angular/core';
import { FinancialYear } from '@app/core/entity/financialyear';
import { ClientService } from '@app/core/services/clients.service';
import { TeamsSelectors } from '@app/core/state/teams/teams.selectors';
import { toClientTransformer } from '@app/core/state/transformers/transformers';
import { ClientType } from '@app/core/state/types';
import { ClientGuideData } from '@app/core/state/types/client-guide.types';
import { openDialog } from '@app/shared/misc/openDialog';
import {
  EMPTY,
  Observable,
  catchError,
  defer,
  filter,
  first,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
  throwError,
  withLatestFrom,
} from 'rxjs';
import { NewClientGuideDialogComponent, Result } from './new-client-guide-dialog.component';
import { Store } from '@ngrx/store';
import { AppState } from '@app/core/state/appState';
import { FinancialYearService } from '@app/core/services/financialyear.service';
import { OnboardingService } from '@app/core/services/onboarding.service';
import { DialogService } from 'primeng/dynamicdialog';
import { Data } from '@angular/router';
import { OnboardConfirmDialogComponent } from './onboard-confirm-dialog.component';
import { OnboardingFinancialInformation } from '@app/core/services/onboarding.service.types';
import { ToastActions } from '@app/core/state/toast/toast.actions';
import { openOnboardingInNewTab } from '@app/shared/misc/openBlappOnboarding';

@Injectable()
export class NewClientGuideService {
  // eslint-disable-next-line no-useless-constructor, max-params
  constructor(
    private store: Store<AppState>,
    private clientService: ClientService,
    private financialYearService: FinancialYearService,
    private onboardingService: OnboardingService,
    private dialogService: DialogService,
  ) {}

  openClientGuideDialogWhenRouteDataContainsGuideInfo(soruce$: Observable<Data>) {
    return soruce$.pipe(
      first(),
      filter(({ guideData }) => guideData?.showGuide),
      switchMap(({ guideData }) => this.openClientGuideDialog(guideData)),
      filter((result) => Boolean(result)),
      switchMap((result) => this.persistNewClient(result)),
      switchMap(({ client, financialInformation }) =>
        this.openOnboardConfirmationDialog({ client, financialInformation }),
      ),
      mergeMap(({ client, financialInformation }) =>
        this.generateBlappOnboardingKeyForClient(client, financialInformation),
      ),
      tap((key) => openOnboardingInNewTab(key)),
    );
  }

  private generateBlappOnboardingKeyForClient(
    client: ClientType,
    financialInformation: OnboardingFinancialInformation,
  ): Observable<string> {
    return this.onboardingService.getOnboardingKey(client.id, { financialInformation });
  }

  private openClientGuideDialog(guideData: ClientGuideData) {
    return openDialog(this.dialogService, NewClientGuideDialogComponent, guideData);
  }

  private persistNewClient(result: Result) {
    // defers the execution of the observable so the teams are loaded
    return defer(() =>
      of(result).pipe(
        filter(Boolean),
        withLatestFrom<{ client: ClientType; financialInformation: OnboardingFinancialInformation }, any>(
          this.store.select(TeamsSelectors.selectNewClientTeamSelector),
        ),
        switchMap(([{ client, financialInformation }, { ids }]) =>
          this.clientService
            .addClient(toClientTransformer.transform(client), ids)
            .pipe(map((newClient) => ({ client: newClient, financialInformation }))),
        ),
        switchMap(({ client, financialInformation }) => this.persistFinancialYear({ client, financialInformation })),
        catchError((err: unknown) => {
          this.dispatchSaveClientError();
          return throwError(() => err);
        }),
      ),
    );
  }

  private persistFinancialYear({ client, financialInformation }) {
    const financialYear = new FinancialYear();
    financialYear.clientId = client.id;
    financialYear.span = financialInformation.financialYear;

    return this.financialYearService
      .saveFinancialYear(financialYear)
      .pipe(map(() => ({ client, financialInformation })));
  }

  private openOnboardConfirmationDialog({ client, financialInformation }) {
    return openDialog(this.dialogService, OnboardConfirmDialogComponent, {}).pipe(
      mergeMap((result) => {
        if (result?.confirmed) {
          return of({ client, financialInformation });
        }
        return EMPTY;
      }),
    );
  }

  private dispatchSaveClientError() {
    this.store.dispatch(
      ToastActions.showErrorMessage({
        summary: 'Ett fel uppstod',
        detail:
          'Något gick fel när information om klienten skulle sparas. Kontrollera uppgifterna på klienten och försök igen.',
      }),
    );
  }
}
