import { CommonModule } from '@angular/common';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PartnerProgramSelectors } from '@app/core/state/partnerprogram/partnerprogram.selectors';
import { BlFrontendAlertComponent } from '@app/core/wrappers/bl-frontend-alert.component';
import { DialogService } from 'primeng/dynamicdialog';
import { openDialog } from '@app/shared/misc/openDialog';
import { CompareCloudCompaniesDialogComponent } from '@app/client/compare-cloud-companies/compare-cloud-companies-dialog.component';

@Component({
  selector: 'app-missing-partner-packages-alert',
  template: `
    <div class="w-full mb-6" *ngIf="(missingPartnerPackages$ | async) > 0">
      <bl-frontend-alert
        severity="warning"
        [header]="'Klienter med förmånsgrundande samarbetspaket saknar koppling till företagsdatabas'"
        [listItems]="[
          {
            text: 'Okopplade klienter med Pro-paket ger ingen återbäring och räknas inte som nivågrundande i Partnerprogrammet'
          },
          {
            text: 'Okopplade klienter med Essential-paket räknas inte som nivågrundande i Partnerprogrammet'
          }
        ]"
        [link]="{
          text: 'Hantera klientkopplingarna nu',
          onClick: openCompareToCloudCompanyDialog
        }"
        [dismissable]="false">
      </bl-frontend-alert>
    </div>
  `,
  standalone: true,
  imports: [CommonModule, BlFrontendAlertComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogService],
})
export class MissingPartnerPackagesAlertComponent {
  missingPartnerPackages$: Observable<number>;

  // eslint-disable-next-line no-useless-constructor
  constructor(private store: Store, private dialogService: DialogService) {
    this.missingPartnerPackages$ = this.store.select(PartnerProgramSelectors.missingPartnerProgramPackages);
  }

  openCompareToCloudCompanyDialog = () => {
    openDialog(this.dialogService, CompareCloudCompaniesDialogComponent, {}).subscribe();
  };
}
