import {
  Component,
  ContentChildren,
  QueryList,
  AfterContentInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { Observable, Subject, filter, takeUntil } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { TabComponent } from './tab.component';

@Component({
  selector: 'app-tabs',
  template: `
    <div class="mb-6 mt-2">
      <ul class="nav nav-tabs">
        <li *ngFor="let tab of tabs; let i = index" (click)="selectTab(tab)" [class.active]="tab.active">
          <a *ngIf="tab.route" routerLink="{{ tab.route }}" routerLinkActive="active">
            <div class="relative">
              <span class="relative">{{ tab.title }}</span>

              <ng-container *ngIf="tab.notificationBadge !== null">
                <div
                  class="flex absolute pin-r pin-t -mr-2 rounded-full text-white text-xs bg-bl-orange-500 h-5 w-5 justify-center items-center -top-2 -right-4">
                  {{ tab.notificationBadge }}
                </div>
              </ng-container>
              <ng-container *ngIf="tab.newFeature">
                <div
                  [ngStyle]="{ opacity: tab.newFeature ? '1' : '0' }"
                  class="absolute -mr-2 rounded-full text-white text-xs bg-bl-orange-500 -top-2 -right-8 px-1">
                  Nyhet
                </div>
              </ng-container>
            </div>
          </a>
          <a
            *ngIf="!tab.route"
            class="tab"
            tooltipPosition="bottom"
            [pTooltip]="tab.disabled ? tab.tooltip : undefined"
            [ngClass]="{ disabled: tab.disabled }">
            {{ tab.title }}
            <ng-container *ngIf="tab.newFeature">
              <div
                [ngStyle]="{ opacity: tab.newFeature ? '1' : '0' }"
                class="absolute rounded-full text-white text-xs bg-bl-orange-500 -top-2 -right-6 px-1">
                Nyhet
              </div>
            </ng-container>
          </a>
        </li>
      </ul>
    </div>
    <ng-content></ng-content>
  `,
  styles: [
    `
      .tab {
        cursor: pointer;
      }
      .disabled {
        color: darkgray;
        cursor: not-allowed;
      }
    `,
  ],
})
export class TabsComponent implements AfterContentInit, OnDestroy {
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;
  @Output() tabChange: EventEmitter<number> = new EventEmitter<number>();

  public unfinishedTodos$: Observable<number>;
  public notifications$: Observable<number>;
  public showNotifications$: Observable<boolean>;

  private onDestroySub: Subject<void>;

  private delayedTabSelection: string = null;

  constructor(private router: Router) {
    this.onDestroySub = new Subject();

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.onDestroySub),
      )
      .subscribe((event: NavigationEnd) => {
        this.delayedTabSelection = null;

        if (!this.tabs) {
          this.delayedTabSelection = event.url;
        }

        if (this.tabs?.some((tab) => Boolean(tab.route))) {
          this.clearActiveTabs();
          this.selectTab(this.tabs.find((tab) => tab.route === event.url));
        }
      });
  }

  get currentTabIndex() {
    return this.tabs.toArray().findIndex((t) => t.active);
  }

  ngAfterContentInit() {
    const activeTab = this.tabs.find((tab) => tab.active || tab.route === this.delayedTabSelection);
    // delay tab selection so we don't get ExpressionChangedAfterItHasBeenCheckedError when render the tabs
    setTimeout(() => {
      this.selectTab(activeTab || this.tabs.first);
    }, 0);
  }

  ngOnDestroy(): void {
    this.onDestroySub.next();
    this.onDestroySub.complete();
  }

  selectTab(tab: TabComponent) {
    if (!tab || tab.disabled) {
      return;
    }

    this.clearActiveTabs();
    tab.active = true;

    this.tabChange.emit(this.currentTabIndex);
  }

  selectTabByTitle(tabTitle: string) {
    setTimeout(() => {
      const tab = this.tabs.find((t) => t.title === tabTitle);
      this.selectTab(tab);
    }, 20);
  }

  selectFirst() {
    if (this.tabs && this.tabs.length > 0) {
      this.selectTab(this.tabs.first);
    }
  }

  private clearActiveTabs() {
    this.tabs.toArray().forEach((tab) => {
      tab.active = false;
    });
  }
}
