import { createSelector } from '@ngrx/store';
import { ListSelectors } from '../list/list.selectors';
import {
  CollaborationPackageActivityFilter,
  CollaborationPackageFilter,
  CollaborationPackageItem,
  CollaborationPackageParams,
  CollaborationPackageStatusFilter,
} from '../types/collaboration-package.types';
import { AppState } from '../appState';

const filterStatusFunctionMap: Record<CollaborationPackageStatusFilter, (item: CollaborationPackageItem) => boolean> = {
  all: () => true,
  allExceptArchived: (item: CollaborationPackageItem) => !item.archived,
  allWithoutSubscription: (item: CollaborationPackageItem) =>
    (!item.subscription?.subscriptionTypeId ||
      item.subscription?.subscriptionTypeId === 2 ||
      (item.subscription?.subscriptionTypeId === 4 && !item.subscription?.modules?.length)) &&
    !item.archived, // subscription type is 2 or subscription type is 4 and has no modules or not set and not archived
  allWithNewSubscription: (item: CollaborationPackageItem) =>
    item.subscription?.subscriptionTypeId === 4 && item.subscription?.modules?.length && !item.archived, // of new subscription type and not archived
  allArchived: (item: CollaborationPackageItem) => item.archived,
  allWithFirmsupport: (item: CollaborationPackageItem) => item.existsInFirmSupport,
  allWithoutFirmsupport: (item: CollaborationPackageItem) => !item.existsInFirmSupport,
};

const getLastLogin = (item: CollaborationPackageItem): Date => (item.lastLogin ? new Date(item.lastLogin) : null);
const getWeekAgo = (): Date => {
  const weekAgo = new Date();
  weekAgo.setDate(weekAgo.getDate() - 7);
  return weekAgo;
};
const getMonthAgo = (): Date => {
  const monthAgo = new Date();
  monthAgo.setMonth(monthAgo.getMonth() - 1);
  return monthAgo;
};
const getYearAgo = (): Date => {
  const yearAgo = new Date();
  yearAgo.setFullYear(yearAgo.getFullYear() - 1);
  return yearAgo;
};

const filterActivityFunctionMap: Record<
  CollaborationPackageActivityFilter,
  (item: CollaborationPackageItem) => boolean
> = {
  all: () => true,
  noActivity: (item: CollaborationPackageItem) => {
    const lastLogin = getLastLogin(item);
    if (!lastLogin) {
      return true;
    }

    const yearAgo = getYearAgo();
    return lastLogin && lastLogin < yearAgo;
  },
  activityCurrentWeek: (item: CollaborationPackageItem) => {
    const lastLogin = getLastLogin(item);
    const weekAgo = getWeekAgo();
    return lastLogin && lastLogin >= weekAgo;
  },
  activityCurrentMonth: (item: CollaborationPackageItem) => {
    const lastLogin = getLastLogin(item);
    const monthAgo = getMonthAgo();
    return lastLogin && lastLogin >= monthAgo;
  },
  activityCurrentYear: (item: CollaborationPackageItem) => {
    const lastLogin = getLastLogin(item);
    const yearAgo = getYearAgo();
    return lastLogin && lastLogin >= yearAgo;
  },
};

const allCloudDbItems = ListSelectors.getItems<
  CollaborationPackageItem,
  CollaborationPackageFilter,
  CollaborationPackageParams
>();

const selectFilteredItems = createSelector(allCloudDbItems, ({ items, filter: { status, activity } }) => {
  const filterStatusFunction = filterStatusFunctionMap[status] || (() => true);
  const filterActivityFunction = filterActivityFunctionMap[activity] || (() => true);
  return items.filter(filterStatusFunction).filter(filterActivityFunction);
});

const collaborationPackageItemState = (state: AppState) => state.collaborationPackageItem;

const selectEmail = createSelector(collaborationPackageItemState, (state) => state.email);
const selectClient = createSelector(collaborationPackageItemState, (state) => state.client);
const selectHasHiddenDatabases = createSelector(
  collaborationPackageItemState,
  (state) => state.meta?.hasHiddenDatabases ?? false,
);
const selectDeleteable = createSelector(collaborationPackageItemState, (state) => Boolean(state.client?.deleteable));
const selectNumberOfMissingCollaborationPackages = createSelector(
  collaborationPackageItemState,
  (state) => state.meta?.numberOfMissingCollaborationPackages ?? 0,
);

export const CollaborationPackageSelectors = {
  selectFilteredItems,
  selectEmail,
  selectClient,
  selectHasHiddenDatabases,
  selectDeleteable,
  selectNumberOfMissingCollaborationPackages,
};
