export const tooltipTextMap = {
  0: {
    title: 'Samarbetspaket saknas',
    description: 'Tryck på knappen <span class="font-semibold">Välj</span> för att hantera paketet.',
  },
  1: {
    title: 'Nuvarande paket',
    description:
      'Bokförings- och faktureringsabonnemang av den gamla typen som behöver bytas ut. Inget val går att göra just nu.',
  },
  2: {
    title: 'Nuvarande paket',
    description:
      'Byråkundsamverkanspaket av den gamla typen som behöver bytas ut.<br>- Tryck på knappen <span class="font-semibold">Välj</span> för att hantera paketet.',
  },
  3: {
    title: 'Nuvarande paket',
    description: 'Bokförings- och faktureringsabonnemang av den nya typen. Inget val går att göra just nu.',
  },
  4: {
    title: 'Gå till app.bjornlunden.se',
    description: 'Är du användare i företaget kan du klicka på mig för att komma direkt in i företaget på webben.',
  },
};
