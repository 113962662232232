import { partnerProgramFeature } from './partnerprogram.reducer';

const {
  selectFirm,
  selectBenefits,
  selectProperties,
  selectLevels,
  selectMissingCollaborationsPackagesDialogIsShowing,
  selectMissingPartnerProgramPackages,
} = partnerProgramFeature;

export const PartnerProgramSelectors = {
  firm: selectFirm,
  benefits: selectBenefits,
  properties: selectProperties,
  levels: selectLevels,
  missingCollaborationsPackagesDialog: selectMissingCollaborationsPackagesDialogIsShowing,
  missingPartnerProgramPackages: selectMissingPartnerProgramPackages,
};
