import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BlFrontendButtonComponent } from '@app/core/wrappers/bl-frontend-button.component';
import { AllInclusiveService } from '@app/core/services/all-inclusive.service';
import {
  DialogData,
  DialogResult,
  FormDialogBaseComponent,
} from '@app/shared/components/form-dialog-base/form-dialog-base.component';

interface Result extends DialogResult {
  confirmed: boolean;
}

@Component({
  template: `
    <div>
      <div>
        <ng-container *ngIf="isLoggedInWithBLTAService; else hhlTemplate">
          <p>
            I nästa steg kommer du till en guide för att skapa och koppla en företagsdatabas i
            <span class="font-bold">BL Administration</span> samt i <span class="font-bold">Webb och app</span>.
          </p>
          <p>
            Du kommer i guiden få välja vilket samarbetpaket och vilka eventuella tilläggstjänster du önskar för
            företaget. Tilläggstjänster kan du välja senare också, men för att skapa en företagsdatabas behöver du minst
            ett Minipaket.
          </p>
          <p>
            Om du väljer att avbryta nu skapas inte företagsdatabasen i BL Administration. Du kan senare komma till
            samma guide via klientkortet i Byråstöd.
          </p>
        </ng-container>
        <ng-template #hhlTemplate>
          <p class="font-bold">
            En helt <span class="underline">ny tom databas</span> kommer skapas som även blir nåbar från Företagslistan
            i Molnet i BL Administration.
          </p>
          <p>
            Vill du kontrollera om företaget är upplagt i denna lista sedan tidigare kan du gå till Klientlistan och
            klicka på knappen Jämför mot Företag i molnet. Klicka då på Avbryt i denna ruta.
          </p>
          <p class="font-bold">Är du säker på att du vill skapa en ny molndatabas klickar du på OK.</p>
        </ng-template>
        <div class="flex justify-end mt-4 space-x-2">
          <bl-frontend-button [text]="'Ok'" (click)="close({ confirmed: true })" />
          <bl-frontend-button [text]="'Avbryt'" variant="secondary" (click)="close({ confirmed: false })" />
        </div>
      </div>
    </div>
  `,
  standalone: true,
  providers: [DialogService, AllInclusiveService],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, BlFrontendButtonComponent],
})
export class OnboardConfirmDialogComponent extends FormDialogBaseComponent<DialogData, Result> {
  get isLoggedInWithBLTAService() {
    return this.allInclusiveService.isLoggedInWithBLTAService;
  }

  constructor(
    protected override ref: DynamicDialogRef,
    protected override config: DynamicDialogConfig<DialogData>,
    private allInclusiveService: AllInclusiveService,
  ) {
    super(ref, config);
  }

  protected static override getDialogConfig(): Omit<DynamicDialogConfig<DialogData>, 'data'> {
    return {
      header: 'Din klient är nu skapad i Byråstöd',
      width: '500px',
      closable: false,
      closeOnEscape: false,
      draggable: false,
      modal: true,
    };
  }
}
