import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PartnerFirm, LevelDto } from '@app/core/state/types/partner.types';
import { BystSharedModule } from '@app/shared/byst-shared.module';
import { PartnerProgramProfileCardComponent } from '../partnerprogram-profile/partnerprogram-profile-card.component';
import { PartnerProgramFaqComponent } from './partnerprogram-faq.component';
import { PartnerProgramCertCardComponent } from './partnerprogram-cert-card.component';
import { PartnerProgramCurrentBenefitsComponent } from './partnerprogram-current-benefits.component';
import { PartnerProgramChartComponent } from './partnerprogram-chart.component';
import { BlFrontendButtonComponent } from '@app/core/wrappers/bl-frontend-button.component';
import { isNullOrUndefined } from '@app/core/entity/validation';
import { BlFrontendAlertComponent } from '@app/core/wrappers/bl-frontend-alert.component';
import { PartnerProgramKeyCertCardComponent } from './partnerprogram-keycert-card.component';
import { PartnerProgramProgressbarComponent } from './partnerprogram-progressbar.component';
import { BlFrontendTooltipComponent } from '@app/core/wrappers/bl-frontend-tooltip.component';
import { PartnerProgramFallenOutAlert } from '../partnerprogram-fallenout-alert/partnerprogram-fallenout-alert.component';
import { PartnerProgramSignUp } from '../partnerprogram-signup/partnerprogram-signup.component';
import { MissingPartnerPackagesAlertComponent } from '../missing-partner-packages-alert/missing-partner-packages-alert.component';

@Component({
  selector: 'app-partnerprogram-overview',
  templateUrl: './partnerprogram-overview.component.html',
  styles: [
    `
      :host .bl-core-alert-header {
        font-weight: 700;
      }
    `,
  ],
  standalone: true,
  imports: [
    CommonModule,
    BystSharedModule,
    PartnerProgramProfileCardComponent,
    PartnerProgramFaqComponent,
    PartnerProgramCertCardComponent,
    PartnerProgramCurrentBenefitsComponent,
    PartnerProgramChartComponent,
    PartnerProgramKeyCertCardComponent,
    BlFrontendButtonComponent,
    BlFrontendAlertComponent,
    PartnerProgramProgressbarComponent,
    BlFrontendTooltipComponent,
    PartnerProgramFallenOutAlert,
    PartnerProgramSignUp,
    PartnerProgramFallenOutAlert,
    MissingPartnerPackagesAlertComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnerProgramOverviewComponent {
  @Input() level: LevelDto | null;
  @Input() levels: LevelDto[];
  @Input() firm: PartnerFirm | null;
  @Output() openTab: EventEmitter<number> = new EventEmitter();

  get isPartner() {
    // null is not a partner
    // zero is a partner that has fallen out of the lowest level
    return !isNullOrUndefined(this.level?.value);
  }

  get hasFallenOut() {
    // null is not a partner
    // zero is a partner that has fallen out of the lowest level
    return this.level?.value === 0;
  }

  get blEssentialsTooltipContent() {
    return `
    Samarbetspaket som innehåller
- Ekonomiöversikt
- Meddelandefunktion
- Mobilapp
- Webb
- Kvittohantering med tolkning
- E-postportal 
- Kreditkort med 1% cashback
- Leverantörsfaktura med tolkning
- Fakturering
    `;
  }

  get blProTooltipContent() {
    return `
    Samarbetspaket som innehåller

- Ekonomiöversikt
- Meddelandefunktion
- Mobilapp
- Webb
- Kvittohantering med tolkning
- E-postportal med tolkning
- Kreditkort med 1 % återbäring
- Fakturering
- Leverantörsfaktura med tolkning
- Attest
- Bank och Betalning
- Integrationer (API) 
    `;
  }
}
