import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, delay, filter, map, mergeMap, of, switchMap, tap } from 'rxjs';
import { PartnerProgramService } from '@app/core/services/partnerprogram.service';
import { PartnerProgramActions } from './partnerprogram.actions';
import { ToastActions } from '../toast/toast.actions';
import { AuthActions } from '../auth/auth.actions';
import { AllInclusiveService } from '@app/core/services/all-inclusive.service';
import { CloudCompanyService } from '@app/core/services/cloud-company.service';
import { CollaborationPackageItem } from '../types/collaboration-package.types';
import { MissingPartnerProgramPackagesDialogComponent } from '@app/partnerprogram/missing-partner-packages-dialog/missing-partner-packages-dialog.component';
import { openDialog } from '@app/shared/misc/openDialog';
import { DialogService } from 'primeng/dynamicdialog';
import { Store } from '@ngrx/store';
import { PartnerProgramSelectors } from './partnerprogram.selectors';

const POLLING_INTERVAL = 5000;
const SHOW_DELAY = 1 * 60 * 1000 - POLLING_INTERVAL;

@Injectable()
export class PartnerProgramEffects {
  private partnerModuleIds;
  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PartnerProgramActions.load),
      switchMap(({ startDate }) =>
        this.partnerProgramService.getFirm('sv', startDate).pipe(
          // eslint-disable-next-line max-nested-callbacks
          mergeMap((data) => [PartnerProgramActions.loadSucceeded({ startDate, data })]),
          // eslint-disable-next-line max-nested-callbacks
          catchError((error: unknown) => of(PartnerProgramActions.loadFailed({ error }))),
        ),
      ),
    ),
  );

  loadBenefits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PartnerProgramActions.loadBenefits),
      switchMap(() =>
        this.partnerProgramService.getBenefits().pipe(
          // eslint-disable-next-line max-nested-callbacks
          mergeMap((benefits) => [PartnerProgramActions.loadBenefitsSucceeded({ benefits })]),
          // eslint-disable-next-line max-nested-callbacks
          catchError((error: unknown) => of(PartnerProgramActions.loadBenefitsFailed({ error }))),
        ),
      ),
    ),
  );

  loadProperties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PartnerProgramActions.loadProperties),
      switchMap(() =>
        this.partnerProgramService.getProperties().pipe(
          // eslint-disable-next-line max-nested-callbacks
          mergeMap((properties) => [PartnerProgramActions.loadPropertiesSucceeded({ properties })]),
          // eslint-disable-next-line max-nested-callbacks
          catchError((error: unknown) => of(PartnerProgramActions.loadPropertiesFailed({ error }))),
        ),
      ),
    ),
  );

  loadLevels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PartnerProgramActions.loadLevels),
      switchMap(() =>
        this.partnerProgramService.getLevels().pipe(
          // eslint-disable-next-line max-nested-callbacks
          mergeMap((levels) => [PartnerProgramActions.loadLevelsSucceeded({ levels })]),
          // eslint-disable-next-line max-nested-callbacks
          catchError((error: unknown) => of(PartnerProgramActions.loadLevelsFailed({ error }))),
        ),
      ),
    ),
  );

  save$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PartnerProgramActions.save),
      switchMap(({ data }) =>
        this.partnerProgramService.updateFirm(data).pipe(
          // eslint-disable-next-line max-nested-callbacks
          mergeMap((d) => [
            ToastActions.showInfoMessage({ summary: 'Sparat', detail: 'Byråprofilen has sparats' }),
            PartnerProgramActions.saveSucceeded({ data: d }),
          ]),
          // eslint-disable-next-line max-nested-callbacks
          catchError((error: unknown) => of(PartnerProgramActions.saveFailed({ error }))),
        ),
      ),
    ),
  );

  initDialogForMissingPartnerProgramPackages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loadCurrentUserSuccessful),
      filter(({ user }) => this.allInclusiveService.isLoggedInWithBLTAService && Boolean(user?.partnerProgram)),
      switchMap(() => this.partnerProgramService.getFirm()),
      filter((firm) => Boolean(firm)),
      switchMap(() =>
        this.cloudCompanyService.getCollaborationCloudCompanies({ responsibleId: null }).pipe(
          map((response) => response.data),
          catchError(() => of([])),
        ),
      ),
      map((collaborationPackages) => this.getNumberOfMissingPartnerProgramPackage(collaborationPackages)),
      filter((numberOfMissingPartnerProgramPackages) => numberOfMissingPartnerProgramPackages > 0),
      mergeMap((numberOfMissingPartnerProgramPackages) => [
        PartnerProgramActions.setMissingPartnerProgramPackages({ numberOfMissingPartnerProgramPackages }),
        PartnerProgramActions.checkShowDialog(),
      ]),
    ),
  );

  checkShowDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PartnerProgramActions.checkShowDialog),
      concatLatestFrom(() => this.store.select(PartnerProgramSelectors.missingCollaborationsPackagesDialog)),
      mergeMap(([, showMissingCollaborationsPackagesDialog]) => {
        if (showMissingCollaborationsPackagesDialog) {
          return of(PartnerProgramActions.checkShowDialog()).pipe(delay(POLLING_INTERVAL));
        }

        return of(PartnerProgramActions.showDialog());
      }),
    ),
  );

  showDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PartnerProgramActions.showDialog),
        delay(SHOW_DELAY),
        concatLatestFrom(() => this.store.select(PartnerProgramSelectors.missingPartnerProgramPackages)),
        filter(([, numberOfMissingPartnerProgramPackages]) => numberOfMissingPartnerProgramPackages > 0),
        tap(([, numberOfMissingPartnerProgramPackages]) =>
          openDialog(this.dialogService, MissingPartnerProgramPackagesDialogComponent, {
            numberOfMissingPartnerProgramPackages,
          }),
        ),
      ),
    { dispatch: false },
  );

  private getNumberOfMissingPartnerProgramPackage(collaborationPackages: CollaborationPackageItem[]): number {
    return (collaborationPackages ?? []).filter((item) => {
      const { subscriptionTypeId, modules } = item.subscription;

      return (
        subscriptionTypeId === 4 &&
        modules.some((m) => this.partnerModuleIds.has(m.id)) &&
        !item.archived &&
        !item.existsInFirmSupport
      );
    }).length;
  }

  // eslint-disable-next-line no-useless-constructor, max-params
  constructor(
    private actions$: Actions,
    private store: Store,
    private partnerProgramService: PartnerProgramService,
    private allInclusiveService: AllInclusiveService,
    private cloudCompanyService: CloudCompanyService,
    private dialogService: DialogService,
  ) {
    this.partnerModuleIds = new Set(['appessential', 'apppro']);
  }
}
