import { AppState } from '@app/core/state/appState';
import { CollaborationPackageActions } from '@app/core/state/collaboration-packages/collaboration-packages.actions';
import { ActionStrategy, ActionStrategyParams, ActionType } from '@app/core/state/types/collaboration-package.types';
import { Store } from '@ngrx/store';
import { formatSocialSecurityNumberWithCentry } from './validation-helper';

const onboardingStrategy = (store: Store<AppState>): ActionStrategy => ({
  type: ActionType.ONBOARD,
  primaryButtonText: 'Välj',
  execute: (config: ActionStrategyParams) => {
    const { cloudApiKey, clientId, email } = config;

    if (clientId) {
      store.dispatch(CollaborationPackageActions.onboardExistingClient({ clientId, email }));
      return;
    }
    store.dispatch(CollaborationPackageActions.onboardNonExistingClient({ cloudApiKey, email }));
  },
});

const onboardingMiniStrategy = (store: Store<AppState>): ActionStrategy => ({
  type: ActionType.ONBOARD_WITH_MINI,
  primaryButtonText: 'Välj',
  execute: (config: ActionStrategyParams) => {
    const { cloudApiKey, name, socialSecurityNumber: rawSocialSecurityNumber } = config;
    // removes any dashes from the social security number so we have the date in format of YYYYMMDDXXXX
    const socialSecurityNumber = formatSocialSecurityNumberWithCentry(rawSocialSecurityNumber);

    store.dispatch(
      CollaborationPackageActions.onboardWithMiniCollaborationPackage({ cloudApiKey, name, socialSecurityNumber }),
    );
  },
});

const changePackageOnNewSubscriptionStrategy = (store: Store<AppState>): ActionStrategy => ({
  type: ActionType.CHANGE_PACKAGE_ON_NEW_SUBSCRIPTION,
  primaryButtonText: 'Fortsätt',
  execute: (config: ActionStrategyParams) => {
    const { cloudApiKey } = config;

    store.dispatch(CollaborationPackageActions.changePackageOnNewSubscriptionType({ cloudApiKey }));
  },
});

const archiveStrategy = (store: Store<AppState>): ActionStrategy => ({
  type: ActionType.ARCHIVE,
  primaryButtonText: 'Arkivera',
  execute: (config: ActionStrategyParams) => {
    const { cloudApiKey, name, years, clientAction } = config;
    store.dispatch(CollaborationPackageActions.archiveCloudDatabase({ cloudApiKey, name, years, clientAction }));
  },
});

const unarchiveStrategy = (store: Store<AppState>): ActionStrategy => ({
  type: ActionType.UNARCHIVE,
  primaryButtonText: 'Fortsätt',
  execute: (config: ActionStrategyParams) => {
    const { cloudApiKey, name, socialSecurityNumber: rawSocialSecurityNumber, clientAction } = config;
    // removes any dashes from the social security number so we have the date in format of YYYYMMDDXXXX
    const socialSecurityNumber = formatSocialSecurityNumberWithCentry(rawSocialSecurityNumber);
    store.dispatch(
      CollaborationPackageActions.unarchiveCloudDatabase({ cloudApiKey, name, socialSecurityNumber, clientAction }),
    );
  },
});

const deleteStrategy = (store: Store<AppState>): ActionStrategy => ({
  type: ActionType.DELETE,
  primaryButtonText: 'Ta bort',
  execute: (config: ActionStrategyParams) => {
    const { cloudApiKey, name, clientAction } = config;
    store.dispatch(CollaborationPackageActions.deleteCloudDatabase({ cloudApiKey, name, clientAction }));
  },
});

export const createStrategyFactory = (store: Store<AppState>) => {
  const strategyMap = {
    [ActionType.ONBOARD]: onboardingStrategy(store),
    [ActionType.ONBOARD_WITH_MINI]: onboardingMiniStrategy(store),
    [ActionType.CHANGE_PACKAGE_ON_NEW_SUBSCRIPTION]: changePackageOnNewSubscriptionStrategy(store),
    [ActionType.ARCHIVE]: archiveStrategy(store),
    [ActionType.UNARCHIVE]: unarchiveStrategy(store),
    [ActionType.DELETE]: deleteStrategy(store),
  };

  return (strategyType: ActionType) => strategyMap[strategyType];
};
