import { DialogData, DialogResult, FormDialogBaseComponent } from '../../form-dialog-base/form-dialog-base.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { BlFrontendButtonComponent } from '@app/core/wrappers/bl-frontend-button.component';
import { PleaseWaitComponent } from '../../please-wait/please-wait.component';

interface Result extends DialogResult {
  confirmed: boolean;
}

@Component({
  selector: 'app-ask-for-collaboration-packages-on-new-client-dialog',
  template: `
    <div>
      <p>
        För att kunna arbeta med kunden i BL App och BL Administration behöver du skapa ett samarbetspaket och en
        företagsdatabas. Tryck på knappen Skapa samarbetspaket i BL App och BLA för att komma igång.
      </p>
      <div class="flex justify-end mt-4 space-x-2">
        <bl-frontend-button [text]="'Hoppa över'" variant="secondary" (click)="close({ confirmed: false })" />
        <bl-frontend-button [text]="'Skapa samarbetspaket i BL App och BLA'" (click)="close({ confirmed: true })" />
      </div>
    </div>
  `,
  standalone: true,
  providers: [DialogService],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, BlFrontendButtonComponent, PleaseWaitComponent],
})
export class AskForCollaborationPackagesOnNewClientDialog extends FormDialogBaseComponent<DialogData, Result> {
  protected static override getDialogConfig(): Omit<DynamicDialogConfig<DialogData>, 'data'> {
    return {
      header: 'Kunden i BL App och BL Administration?',
      draggable: false,
      modal: true,
      width: '500px',
    };
  }
}
