import { DialogData, DialogResult, FormDialogBaseComponent } from '../../form-dialog-base/form-dialog-base.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BlFrontendButtonComponent } from '@app/core/wrappers/bl-frontend-button.component';
import { PleaseWaitComponent } from '../../please-wait/please-wait.component';
import { AllInclusiveService } from '@app/core/services/all-inclusive.service';
import { validateEmail } from '@app/shared/misc/email-validator';

interface ConfirmDialogData extends DialogData {
  email: string;
}

interface Result extends DialogResult {
  confirmed: boolean;
  email?: string;
}

@Component({
  selector: 'app-create-clouddb-confirmation-dialog',
  template: `
    <div>
      <div>
        <ng-container *ngIf="isLoggedInWithBLTAService; else hhlTemplate">
          <p>
            Här kopplas kundens företag till våra tjänster så att ni kan samarbeta i
            <span class="font-bold">Webb och app</span> samt <span class="font-bold">BL Administration</span>.
          </p>
          <p>
            I nästa steg kommer du till en guide där du väljer ett samarbetspaket och eventuella tilläggstjänster för
            kunden.
          </p>
          <p>Innan du börjar skapa ett samarbetspaket behöver du fylla i e-postadress för kundens företag.</p>
          <div class="flex flex-col mt-4">
            <label class="font-bold block" for="email">Epostadress till företaget *</label>
            <input pInputText name="email" type="email" placeholder="Epostadress" [(ngModel)]="email" />
          </div>
        </ng-container>
        <ng-template #hhlTemplate>
          <p class="font-bold">
            En helt <span class="underline">ny tom databas</span> kommer skapas som även blir nåbar från Företagslistan
            i Molnet i BL Administration.
          </p>
          <p>
            Vill du kontrollera om företaget är upplagt i denna lista sedan tidigare kan du gå till Klientlistan och
            klicka på knappen Jämför mot Företag i molnet. Klicka då på Avbryt i denna ruta.
          </p>
          <p class="font-bold">Är du säker på att du vill skapa en ny molndatabas klickar du på OK.</p>
        </ng-template>
        <div class="flex justify-end mt-4 space-x-2">
          <bl-frontend-button [text]="'Ok'" [disabled]="!isValid" (click)="close({ confirmed: true, email })" />
          <bl-frontend-button [text]="'Avbryt'" variant="secondary" (click)="close({ confirmed: false })" />
        </div>
      </div>
    </div>
  `,
  standalone: true,
  providers: [DialogService, AllInclusiveService],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, BlFrontendButtonComponent, PleaseWaitComponent],
})
export class CreateClouddbConfirmationDialog extends FormDialogBaseComponent<ConfirmDialogData, Result> {
  email: string;

  get isLoggedInWithBLTAService() {
    return this.allInclusiveService.isLoggedInWithBLTAService;
  }

  get isLoggedInWithAllInclusiveService() {
    return this.allInclusiveService.isLoggedInWithAllInclusiveService;
  }

  get isValid(): boolean {
    if (this.isLoggedInWithAllInclusiveService) {
      return true;
    }

    return this.isLoggedInWithBLTAService && validateEmail(this.email);
  }

  constructor(
    protected override ref: DynamicDialogRef,
    protected override config: DynamicDialogConfig<ConfirmDialogData>,
    private allInclusiveService: AllInclusiveService,
  ) {
    super(ref, config);
    this.email = config.data.email;
  }

  protected static override getDialogConfig(): Omit<DynamicDialogConfig<DialogData>, 'data'> {
    const allInclusiveService = new AllInclusiveService(null);
    let header = 'Bekräfta skapande av molndatabas';

    if (allInclusiveService.isLoggedInWithBLTAService) {
      header = 'Bekräfta aktivering av nytt samarbetspaket för kunden';
    }

    return {
      header,
      width: '500px',
      closable: false,
      closeOnEscape: false,
      draggable: false,
      modal: true,
    };
  }
}
