<ng-container>
  <app-tabs>
    <app-tab tabTitle="Start" route="/start"></app-tab>
    <app-tab route="/activities" tabTitle="Alla aktiviteter"></app-tab>
    <app-tab route="/accounting" tabTitle="Bokföring"></app-tab>
    <app-tab route="/salaries" tabTitle="Löneadministration"></app-tab>
    <app-tab route="/taxdeclaration" tabTitle="Skattedeklaration"></app-tab>
    <app-tab route="/closing" tabTitle="Bokslut"></app-tab>
    <app-tab route="/incometaxdeclaration" tabTitle="Inkomstdeklaration"></app-tab>
    <app-tab route="/incomestatement" tabTitle="Kontrolluppgifter m.m."></app-tab>
    <app-tab route="/todo" tabTitle="Att göra" [notificationBadge]="unfinishedTodos$ | async"></app-tab>
    <app-tab route="/notifications" tabTitle="Aviseringar m.m."></app-tab>
    <app-tab route="/clients" tabTitle="Klienter"></app-tab>
    <app-tab route="/collaborationpackages" tabTitle="Samarbetspaket" *appShowIfBlFirmService [notificationBadge]="numberOfMissingCollaborationPackages$ | async"></app-tab>
    <app-tab route="/advisorytool" tabTitle="Rådgivarverktyg" [notificationBadge]="advisoryNotification$ | async"></app-tab>
    <app-tab route="/sustainability" tabTitle="Hållbarhetsredovisning"></app-tab>
  </app-tabs>
</ng-container>

<router-outlet></router-outlet>