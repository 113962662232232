import { CommonModule } from '@angular/common';
import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { BlFrontendButtonComponent } from '@app/core/wrappers/bl-frontend-button.component';
import { BystSharedModule } from '@app/shared/byst-shared.module';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import { ClientFormGroup, ClientGuideForm } from '../new-client-guide.types';
import {
  CorporateIdentityFormValueType,
  CorporateIdentityInputComponent,
} from '@app/shared/components/corporate-identity-input/corporate-identity-input.component';
import { DropdownModule } from 'primeng/dropdown';
import { Store } from '@ngrx/store';
import { AppState } from '@app/core/state/appState';
import { ClientTypesSelectors } from '@app/core/state/clientTypes/clientTypes.selectors';
import { ClientTypeType } from '@app/core/state/types';

@Component({
  selector: 'app-step1-basic-information',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, BlFrontendButtonComponent, BystSharedModule, ReactiveFormsModule, DropdownModule],
  template: `
    <div class="flex flex-col w-full flex-wrap" [formGroup]="clientForm">
      <div class="flex flex-col mr-4">
        <app-corporate-identity-input
          #corporateIdentityInput
          [formControl]="corporateIdentityFormControl"
          (onButtonClick)="onLoadCreditinformationButtonClick()">
        </app-corporate-identity-input>
        <p class="text-bl-red-600 mt-2" *ngIf="hasUniqueError">
          Organisationsnumret finns redan på en klient i Byråstöd
        </p>
      </div>
      <div class="flex flex-col mt-3">
        <label for="name">Namn</label>
        <input type="text" formControlName="name" pInputText />
      </div>

      <div class="flex flex-col mt-3">
        <label class="table-label" for="clientType">Typ</label>
        <p-dropdown
          name="clientType"
          [options]="clientTypes$ | async"
          styleClass="w-full"
          [required]="true"
          placeholder="- Välj -"
          formControlName="type"
          optionLabel="description"
          [autoOptionFocus]="false">
        </p-dropdown>
      </div>
      <div class="flex flex-col mt-3">
        <label for="address">Adress</label>
        <input type="text" formControlName="address" pInputText />
      </div>
      <div class="flex justify-between mt-3">
        <div class="flex flex-col mr-10 w-full">
          <label for="postalCode">Postnummer</label>
          <input type="text" formControlName="zipCode" pInputText />
        </div>
        <div class="flex flex-col w-full">
          <label for="city">Ort</label>
          <input type="text" formControlName="city" pInputText />
        </div>
      </div>
      <div class="flex justify-between mt-3">
        <div class="flex flex-col mr-10 w-full">
          <label for="phone">Telefon</label>
          <input type="text" formControlName="phone" pInputText />
        </div>
        <div class="flex flex-col w-full">
          <label for="phone">Mobil</label>
          <input type="text" formControlName="cellphone" pInputText />
        </div>
      </div>
      <div class="flex flex-col mt-3">
        <label for="email">E-postadress till företaget (krävs för koppling av samarbetspaket)</label>
        <input type="text" formControlName="email" pInputText />
      </div>
    </div>
  `,
})
export class Step1BasicInformationComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('corporateIdentityInput', { static: true }) corporateIdentityInput: CorporateIdentityInputComponent;
  @Input() formGroupName: keyof ClientGuideForm;
  @Output() loadCreditInformation = new EventEmitter<string>();

  clientForm: FormGroup<ClientFormGroup>;
  corporateIdentityFormControl: FormControl<CorporateIdentityFormValueType>;
  clientTypes$: Observable<ClientTypeType[]>;

  private rootForm: FormGroup<ClientGuideForm>;
  private onDestroy$ = new Subject<void>();

  get hasUniqueError() {
    return this.clientForm.get('corporateIdentity').hasError('notUnique');
  }

  constructor(rootFormGroup: FormGroupDirective, private store: Store<AppState>) {
    this.rootForm = rootFormGroup.control;
    this.clientTypes$ = this.getClientTypesObservable();

    // Create seperate form for the corp. identity to handle the multiple returning values from its input
    this.corporateIdentityFormControl = this.createCorporateIdentityFormControl(this.getCorporateIdentityInitValues());
  }

  ngOnInit(): void {
    this.clientForm = this.rootForm.get(this.formGroupName) as FormGroup;

    this.listenForCorporateIdentityChanges();
    this.listenForCorporateIdentityStatusChanges();
  }

  ngAfterViewChecked(): void {
    this.corporateIdentityInput.focus();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onLoadCreditinformationButtonClick() {
    const { corporateIdentity } = this.clientForm.value;
    this.loadCreditInformation.emit(corporateIdentity);
  }

  private patchClientWithCorporateIdentity(value: CorporateIdentityFormValueType) {
    const { corporateIdentity, foreignCorporateIdentity } = value;
    this.clientForm.patchValue({ corporateIdentity, foreignCorporateIdentity });
  }

  private getCorporateIdentityInitValues() {
    const { corporateIdentity = '', foreignCorporateIdentity = false } = this.rootForm.get('client').value;
    return { corporateIdentity, foreignCorporateIdentity };
  }

  private getClientTypesObservable(): Observable<ClientTypeType[]> {
    return this.store.select(ClientTypesSelectors.all);
  }

  private createCorporateIdentityFormControl(
    initValue: CorporateIdentityFormValueType,
  ): FormControl<CorporateIdentityFormValueType> {
    return new FormControl({
      value: initValue,
      disabled: false,
    });
  }

  private listenForCorporateIdentityChanges() {
    // Patch the client form with the corporate identity values
    this.corporateIdentityFormControl.valueChanges
      .pipe(
        tap((value) => this.patchClientWithCorporateIdentity(value)),
        takeUntil(this.onDestroy$),
      )
      .subscribe();
  }

  private listenForCorporateIdentityStatusChanges() {
    // Merge the errors from the corporate identity form control with the client form control
    this.corporateIdentityFormControl.statusChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      const clientCorporateIdentityFormControl = this.clientForm.get('corporateIdentity');
      const errors = {
        ...this.corporateIdentityFormControl.errors,
        ...clientCorporateIdentityFormControl.errors,
      };
      clientCorporateIdentityFormControl.setErrors(errors);
    });
  }
}
