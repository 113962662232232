import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { DialogService } from 'primeng/dynamicdialog';
import { EMPTY, Observable, catchError, map, mergeMap, of, tap, throwError } from 'rxjs';
import { openDialog } from '@app/shared/misc/openDialog';
import { State } from '@app/core/entity/state';
import { Client } from '@app/core/entity/client';
import { CloudCompanyService } from '@app/core/services/cloud-company.service';
import { ToastActions } from '@app/core/state/toast/toast.actions';
import { ExportWizardDialogComponent, WizardResponse } from './export-wizard-dialog.component';
import { OnboardingService } from '@app/core/services/onboarding.service';
import { openOnboardingInNewTab } from '@app/shared/misc/openBlappOnboarding';

@Injectable()
export class CreateCloudDbWizardService {
  // eslint-disable-next-line no-useless-constructor, max-params
  constructor(
    private store: Store<State>,
    private dialogService: DialogService,
    private onboardingService: OnboardingService,
    private cloudCompanyService: CloudCompanyService,
  ) {}

  runOnboardingWizard(client: Client): Observable<void> {
    return this.showExportWizardForOnboarding(client);
  }

  runCreateCloudDbWizard(client: Client): Observable<string> {
    return this.showExportWizardForCreateCloudDB(client);
  }

  private showExportWizardForOnboarding(client: Client): Observable<void> {
    return this.getDialogObservable(client).pipe(
      mergeMap((result) => this.generateBlappOnboardingKeyForClient(client, result)),
      tap((key) => openOnboardingInNewTab(key)),
      mergeMap(() => EMPTY),
    );
  }

  private showExportWizardForCreateCloudDB(client: Client): Observable<string | null> {
    return this.getDialogObservable(client).pipe(
      mergeMap((result) => this.cloudCompanyService.exportClientToCloud(result)),
      map((result) => result.cloudApiKey),
      tap(() => this.dispatchCreateCloudDbSuccess()),
      catchError((err: unknown) => {
        this.dispatchCreateCloudDbError();
        return throwError(() => err);
      }),
    );
  }

  private getDialogObservable(client: Client): Observable<WizardResponse> {
    return openDialog(this.dialogService, ExportWizardDialogComponent, { client }).pipe(
      mergeMap((result) => (result ? of(result) : EMPTY)), // if result is null (cancel was clicked), return empty observable
    );
  }

  private generateBlappOnboardingKeyForClient(client: Client, result: WizardResponse): Observable<string> {
    const { accountingMethod, financialYear, vatAccountingInterval } = result;
    const financialInformation = { accountingMethod, financialYear, vatAccountingInterval };
    return this.onboardingService.getOnboardingKey(client.id, { financialInformation });
  }

  private dispatchCreateCloudDbSuccess() {
    this.store.dispatch(
      ToastActions.showInfoMessage({
        summary: 'Skapandet lyckades',
        detail: 'Ny molndatabas har skapats för klienten',
      }),
    );
  }

  private dispatchCreateCloudDbError() {
    this.store.dispatch(
      ToastActions.showErrorMessage({
        summary: 'Ett fel uppstod',
        detail: 'Något gick fel när molndatabasen skulle skapas. Kontrollera uppgifterna på klienten och försök igen.',
      }),
    );
  }
}
