import { createReducer, on } from '@ngrx/store';
import { CollaborationPackageActions } from './collaboration-packages.actions';
import { CollaborationPackageItemState } from '../types/collaboration-package.types';

const initialState: CollaborationPackageItemState = {
  email: null,
  client: null,
  meta: null,
  error: null,
};

export const collaborationPackageItemReducer = createReducer(
  initialState,
  on(CollaborationPackageActions.clearState, (state, { excludeMeta }) => ({
    ...initialState,
    meta: excludeMeta ? state.meta : null,
  })),
  on(CollaborationPackageActions.loadEmailForClientSucceeded, (state, { email }) => ({
    ...state,
    email,
    error: null,
  })),
  on(CollaborationPackageActions.loadEmailForClientFailed, (state, { error }) => ({
    ...state,
    email: null,
    error,
  })),
  on(CollaborationPackageActions.loadClientSucceeded, (state, { client }) => ({
    ...state,
    client,
  })),
  on(CollaborationPackageActions.setMeta, (state, { meta }) => ({
    ...state,
    meta,
  })),

  // manually decrement the number of missing collaboration packages when onboarding with
  // mini collaboration package, archived and deleted succeeded, so we don't have to reload the whole list
  on(
    CollaborationPackageActions.onboardWithMiniCollaborationPackageSucceeded,
    CollaborationPackageActions.archiveCloudDatabaseSucceeded,
    CollaborationPackageActions.deleteCloudDatabaseSucceeded,
    (state) => ({
      ...state,
      meta: {
        ...state.meta,
        numberOfMissingCollaborationPackages: state.meta.numberOfMissingCollaborationPackages - 1,
      },
    }),
  ),
);
