import { Injectable } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { ClientGuideForm, FinancialYearFormGroup } from './new-client-guide.types';
import { ClientType, SpanType } from '@app/core/state/types';
import { OnboardingFinancialInformation } from '@app/core/services/onboarding.service.types';
import { map, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@app/core/state/appState';
import { ClientSelectors } from '@app/core/state/clients/clients.selectors';
import { validateDateFn } from '@app/core/entity/validation';
import { areDaysBetweenDatesOk, isFirstDateAfterSecondDate } from '@app/shared/misc/dates';

@Injectable()
export class NewClientGuideFormService {
  mainForm: FormGroup<ClientGuideForm>;

  // eslint-disable-next-line no-useless-constructor
  constructor(private formBuilder: FormBuilder, private store: Store<AppState>) {}

  createForm(): FormGroup {
    this.mainForm = this.formBuilder.group<ClientGuideForm>({
      client: this.createClientForm(),
      financialInformation: this.createFinancialInformationForm(),
    });

    // Workaround for the styling - Set name and email to touched to show the red border from start
    this.mainForm.get('client').get('name').markAsTouched();
    this.mainForm.get('client').get('email').markAsTouched();

    return this.mainForm;
  }

  getFormValue(): { client: Partial<ClientType>; financialInformation: OnboardingFinancialInformation } {
    const { client, financialInformation } = this.mainForm.value;
    return { client, financialInformation: financialInformation as OnboardingFinancialInformation };
  }

  patchClientData(client: Partial<ClientType>): void {
    this.mainForm.get('client').patchValue({ ...(client as ClientType) });
  }

  patchFinancialYear(financialYearSpan: SpanType): void {
    const { start, end } = financialYearSpan;
    const financialInformationData = {
      financialYear: { start, end },
    };

    this.mainForm
      .get('financialInformation')
      .patchValue({ ...(financialInformationData as OnboardingFinancialInformation) });
  }

  isFormValid(): boolean {
    if (this.mainForm.valid) {
      return true;
    }

    const financialInformationForm = this.mainForm.get('financialInformation') as FormGroup;

    // check if the client form only has valid errors + financial information form is valid
    return this.isClientInformationValid() && financialInformationForm.valid;
  }

  isClientInformationValid(): boolean {
    const clientForm = this.mainForm.get('client') as FormGroup;

    if (clientForm.valid) {
      return true;
    }
    return this.hasOnlyValidClientErrors(clientForm);
  }

  private hasOnlyValidClientErrors(clientForm: FormGroup): boolean {
    const validErrors = ['corporateIdentity', 'notUnique'];
    const hasNotValidErrors = (error: string) => !validErrors.includes(error);

    const errors = Object.values(clientForm.controls)
      .map((control) => control.errors)
      .filter((error) => Boolean(error))
      .flatMap((error) => Object.keys(error))
      .filter(hasNotValidErrors);

    return errors.length === 0;
  }

  private createClientForm(): FormGroup {
    const corporateIdentity = this.formBuilder.control('', {
      validators: [Validators.required],
      asyncValidators: this.validateUniqueCorporateIdentityFn(),
    });

    return this.formBuilder.group({
      corporateIdentity,
      foreignCorporateIdentity: [],
      name: ['', Validators.required],
      address: [],
      zipCode: [],
      city: [],
      email: ['', [Validators.required, Validators.email]],
      phone: [],
      cellphone: [],
      accountant: [],
      businessArea: [],
      description: [],
      registrationDate: [],
      webpage: [],
      residence: [],
      type: [],
    });
  }

  private createFinancialInformationForm(): FormGroup {
    return this.formBuilder.group({
      financialYear: this.formBuilder.group(
        {
          start: ['', [Validators.required, validateDateFn({ key: 'start' })]],
          end: ['', [Validators.required, validateDateFn({ key: 'end' })]],
        },
        {
          validators: [this.validateDateOrderFn, this.validateDateBetweenFn],
        },
      ),
      accountingMethod: ['', Validators.required],
      vatAccountingInterval: ['', Validators.required],
    });
  }

  private validateUniqueCorporateIdentityFn(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> =>
      this.store
        .select(ClientSelectors.checkUniqueOrgNr(control.value))
        .pipe(map((isUnique) => (isUnique ? null : { notUnique: true })));
  }

  private validateDateOrderFn(group: FormGroup<FinancialYearFormGroup>): ValidationErrors | null {
    const { start, end } = group.value;
    return isFirstDateAfterSecondDate(end, start) ? null : { dateOrder: true };
  }

  private validateDateBetweenFn(group: FormGroup<FinancialYearFormGroup>): ValidationErrors | null {
    const { start, end } = group.value;
    return areDaysBetweenDatesOk(start, end) ? null : { dateBetween: true };
  }
}
