import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StartIframeComponent } from './start/start-iframe.component';
import { ActivitiesListComponent } from './activity/activities.list.component';
import { AccountingListComponent } from './accounting/accounting.list.component';
import { ClosingListComponent } from './closing/closing.list.component';
import { IncomeStatementListComponent } from './incomestatement/incomestatement.list.component';
import { SalaryListComponent } from './salary/salary.list.component';
import { IncomeTaxDeclarationListComponent } from './incometaxdeclaration/incometaxdeclaration.list.component';
import { TaxDeclarationListComponent } from './taxdeclaration/taxdeclaration.list.component';
import { TodoListComponent } from './todo/todo.list.component';
import { AdvisoryToolComponent } from './advisory-tool/components/advisory-tool.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { MainAppComponent } from './start/main-app.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './core/guards/auth.guard';
import { SilentAuthComponent } from './login/silent-auth.component';
import { ClientListComponent } from './client/client.list.component';
import { CompositeType } from './core/state/types';
import { SustainabilityReportingComponent } from './sustainability-reporting/sustainability-reporting.component';
import { CollaborationPackagesComponent } from './collaboration-packages/collaboration-packages.component';
import { PartnerProgramComponent } from './partnerprogram/partnerprogram.component';
import { BlaAuthComponent } from './login/bla-auth.component';
import { jwtParamGuard } from './core/guards/jwt-param.guard';
import { guideResolver } from './core/resolvers/guide.resolver';

const appRoutes: Routes = [
  // this path is used by the SSO login / Gateway as redirect uri after successfully login
  { path: 'auth', component: SilentAuthComponent },
  // this path is used by BLA to login with their JWT token
  { path: 'blaauth', component: BlaAuthComponent, canActivate: [jwtParamGuard] },

  { path: 'login', component: LoginComponent },
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: MainAppComponent,
    children: [
      { path: '', component: StartIframeComponent },
      { path: 'start', component: StartIframeComponent },
      { path: 'advisorytool', component: AdvisoryToolComponent },
      { path: 'activities', component: ActivitiesListComponent },
      { path: CompositeType.accounting, component: AccountingListComponent },
      { path: CompositeType.salaries, component: SalaryListComponent },
      { path: CompositeType.closing, component: ClosingListComponent },
      { path: CompositeType.incomeStatement, component: IncomeStatementListComponent },
      { path: CompositeType.incomeTaxDeclaration, component: IncomeTaxDeclarationListComponent },
      { path: CompositeType.taxDeclaration, component: TaxDeclarationListComponent },
      {
        path: 'clients/guide',
        component: ClientListComponent,
        resolve: { guideData: guideResolver },
      },
      { path: 'clients', component: ClientListComponent },
      { path: 'todo', component: TodoListComponent },
      { path: 'notifications', component: NotificationsComponent },
      { path: 'sustainability', component: SustainabilityReportingComponent },
      { path: 'collaborationpackages', component: CollaborationPackagesComponent },
      { path: 'partnerprogram', component: PartnerProgramComponent },
    ],
  },
  { path: '**', redirectTo: '' },
];

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(appRoutes, {});
