import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@app/core/state/appState';
import { AuthActions } from '@app/core/state/auth/auth.actions';
import { ActivatedRoute } from '@angular/router';

@Component({
  template: 'Du loggas in, vänligen vänta...',
})
export class BlaAuthComponent {
  // This is a landing page for login from BLA.
  // The page will only be loaded if a valid JWT has been passed as query param.
  // loginSuccessful action will redirect to specified redirectUrl.
  constructor(store: Store<AppState>, activatedRoute: ActivatedRoute) {
    // Set redirectUrl to the one passed as query param, or default to 'clients/guide'
    const redirectUrl = activatedRoute.snapshot.queryParams.redirectUrl ?? 'clients/guide';
    store.dispatch(AuthActions.setRedirectAfterLoginSuccessful({ redirectUrl }));
  }
}
