import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { UserType } from '../types';

export const AuthActions = createActionGroup({
  source: 'Auth',
  events: {
    'Login Spinner start': emptyProps(),
    'Login Spinner stopp': emptyProps(),

    'Init login': emptyProps(),
    'Login successful': props<{ token: string }>(),
    'After login successful': emptyProps(),
    'Set redirect after login successful': props<{ redirectUrl: string }>(),
    'Clear redirect url': emptyProps(),

    'Load Current User Successful': props<{ user: UserType }>(),
    'Load Current User Failed': props<{ error: unknown }>(),

    'Reload current auth user if not loaded': emptyProps(),
    'Reload current auth user': emptyProps(),
    'Reset auth state and storage': emptyProps(),
  },
});
