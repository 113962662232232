import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  forwardRef,
  signal,
} from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { ControlValueAccessorFn } from '@app/core/state/angularTypes';
import { isValidCorporateIdentity } from '@bl/functions';
import { CheckboxChangeEvent } from 'primeng/checkbox';
import { InputMask } from 'primeng/inputmask';

export interface CorporateIdentityFormValueType {
  corporateIdentity: string;
  foreignCorporateIdentity: boolean;
}

@Component({
  template: `
    <label
      [ngClass]="labelClass"
      [for]="foreignCorporateIdentity ? 'corporateIdentityInput' : 'corporateIdentityInputMask'"
      >Organisationsnummer</label
    >
    <div class="p-inputgroup">
      <p-inputMask
        #corporateIdentityInputMask
        inputId="corporateIdentityInputMask"
        [hidden]="foreignCorporateIdentity"
        mask="999999-9999"
        [pTooltip]="corporateIdentityTooltip()"
        tooltipPosition="top"
        [(ngModel)]="corporateIdentity"
        (ngModelChange)="onCorporateIdentityChange($event)"
        (onBlur)="validateCorporateIdentity()"
        (onFocus)="isFieldTouched.set(true)"
        [autoClear]="false"
        [styleClass]="styleClass"
        pasteValidCorporateIdentity />
      <input
        #corporateIdentityInput
        id="corporateIdentityInput"
        [hidden]="!foreignCorporateIdentity"
        type="text"
        pInputText
        [ngModel]="corporateIdentity"
        (ngModelChange)="onCorporateIdentityChange($event)" />
      <span class="p-inputgroup-addon">
        <p-checkbox
          [(ngModel)]="foreignCorporateIdentity"
          [binary]="true"
          class="pr-2"
          (onChange)="onForeignCorporateIdentityChange($event)"></p-checkbox>
        Utländskt
      </span>
      <button
        *ngIf="!foreignCorporateIdentity"
        class="btn btn-primary"
        pButton
        type="button"
        [disabled]="!foreignCorporateIdentity && (!corporateIdentity || !isValidCorporateIdentity())"
        [label]="isNewClient ? 'Hämta' : 'Uppdatera'"
        (click)="onButtonClick.emit()"></button>
    </div>
  `,
  selector: 'app-corporate-identity-input',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CorporateIdentityInputComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => CorporateIdentityInputComponent), multi: true },
  ],
})
export class CorporateIdentityInputComponent implements ControlValueAccessor, Validator {
  @ViewChild('corporateIdentityInput', { static: true }) corporateIdentityInput: ElementRef;
  @ViewChild('corporateIdentityInputMask', { static: true }) corporateIdentityInputMask: InputMask;

  @Input() isNewClient = true;
  @Input() labelClass: string;

  @Input() foreignCorporateIdentity: boolean;
  @Output() foreignCorporateIdentityChange = new EventEmitter<CheckboxChangeEvent>();

  @Input() corporateIdentity: string;
  @Output() corporateIdentityChange = new EventEmitter<string>();

  @Output() onLocalCorporateIdentityBlur = new EventEmitter<void>();
  @Output() onButtonClick = new EventEmitter<void>();

  isFieldDirty = signal(false);
  isFieldTouched = signal(false);
  isValidCorporateIdentity = signal(false);
  corporateIdentityTooltip = signal('');

  private inputReceivedFocus = false;
  private onChange: ControlValueAccessorFn = () => {};
  private onTouched: ControlValueAccessorFn = () => {};

  // To get the right border color on the input field when it's invalid
  get styleClass() {
    const baseClass = 'corporate-identity-field';
    if (this.isValidCorporateIdentity()) {
      return baseClass;
    }
    if (this.isFieldDirty()) {
      return `${baseClass} ng-invalid ng-dirty`;
    }
    return `${baseClass} ng-invalid ng-touched`;
  }

  focus() {
    if (this.foreignCorporateIdentity) {
      this.setFocusOnCorporateIdentityInput();
      return;
    }
    this.setFocusOnCorporateIdentityMaskInput();
  }

  registerOnChange(fn: ControlValueAccessorFn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: ControlValueAccessorFn): void {
    this.onTouched = fn;
  }

  onCorporateIdentityChange(corporateIdentity: string) {
    this.corporateIdentity = corporateIdentity;
    this.onChange({ corporateIdentity, foreignCorporateIdentity: this.foreignCorporateIdentity });
    this.onTouched();
    this.validateCorporateIdentity();
    this.corporateIdentityChange.emit(corporateIdentity);
  }

  onForeignCorporateIdentityChange(event: CheckboxChangeEvent) {
    this.foreignCorporateIdentity = event.checked;
    this.onChange({ corporateIdentity: this.corporateIdentity, foreignCorporateIdentity: event.checked });
    this.onTouched();
    this.isFieldDirty.set(true);
    this.foreignCorporateIdentityChange.emit(event);
  }

  writeValue(obj: CorporateIdentityFormValueType): void {
    if (obj) {
      this.corporateIdentity = obj.corporateIdentity;
      this.foreignCorporateIdentity = obj.foreignCorporateIdentity;
      this.validateCorporateIdentity();
    }
  }

  validate(): ValidationErrors {
    return this.isValidCorporateIdentity() ? null : { corporateIdentity: 'Felaktigt organisationsnummer' };
  }

  validateCorporateIdentity() {
    if (this.foreignCorporateIdentity) {
      this.corporateIdentityTooltip.set('');
      this.isValidCorporateIdentity.set(true);
      return;
    }

    if (!this.corporateIdentity) {
      this.corporateIdentityTooltip.set('');
      return;
    }

    this.isFieldDirty.set(true);
    this.isFieldTouched.set(true);
    this.isValidCorporateIdentity.set(isValidCorporateIdentity(this.corporateIdentity));
    this.corporateIdentityTooltip.update(() =>
      this.isValidCorporateIdentity() ? '' : 'Felaktigt organisationsnummer',
    );
  }

  private setFocusOnCorporateIdentityMaskInput() {
    const inputMaskElement = this.corporateIdentityInputMask?.el.nativeElement;

    // just return if the input has already received focus
    if (!inputMaskElement || this.inputReceivedFocus) {
      return;
    }

    const input: HTMLInputElement = inputMaskElement.querySelector('input');

    if (!input) {
      return;
    }

    // ExpressionChangedAfterItHasBeenCheckedError is thrown in primeng-inputmask if the focus is set directly in ngAfterViewChecked
    setTimeout(() => {
      input.focus();
      this.inputReceivedFocus = document.activeElement === input;
    }, 0);
  }

  private setFocusOnCorporateIdentityInput() {
    // just return if the client isn't using foreign corporate identity or the input has already received focus
    if (!this.corporateIdentityInput?.nativeElement || this.inputReceivedFocus) {
      return;
    }

    this.corporateIdentityInput.nativeElement.focus();
    this.inputReceivedFocus = document.activeElement === this.corporateIdentityInput?.nativeElement;
  }
}
