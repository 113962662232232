import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SafeHtmlPipe } from '@app/shared/pipes/safe-html.pipe';
import { TooltipOptions } from 'primeng/api';
import { TooltipModule } from 'primeng/tooltip';
import { environment } from 'src/environments/environment';
import { tooltipTextMap } from './tooltip-text';

@Component({
  selector: 'app-subscription-type-icon',
  template: `<div
      *ngIf="!typeId"
      class="h-8 w-full"
      [pTooltip]="tooltipContent"
      [tooltipOptions]="tooltipOptions"></div>
    <div class="flex justify-center" *ngIf="typeId">
      <a [href]="pageUrl" target="_blank">
        <img
          [src]="iconUrl"
          class="iconsize hover:border-b border-bl-blue-600"
          [pTooltip]="tooltipContent"
          [tooltipOptions]="tooltipOptions" />
      </a>
    </div>
    <ng-template #tooltipContent>
      <div class="font-semibold mb-3">{{ tooltip.title }}</div>
      <div [innerHTML]="tooltip.description | safeHtml"></div>
    </ng-template> `,
  styles: [
    `
      img.iconsize {
        height: 24px;
      }
    `,
  ],
  standalone: true,
  imports: [CommonModule, TooltipModule, SafeHtmlPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionTypeIconComponent {
  @Input() typeId: number;
  @Input() cloudApiKey: string;

  tooltipOptions: TooltipOptions = { escape: false, tooltipStyleClass: 'subscription-type-tooltip' };
  private baseIconUrl = 'assets/blapp-subscription-type-icons/sub_type';

  get pageUrl(): string {
    return `${environment.blappWeb}/${this.cloudApiKey || ''}`;
  }

  get iconUrl(): string {
    return `${this.baseIconUrl}${this.typeId}.png`;
  }

  get tooltip(): { title: string; description: string } {
    return tooltipTextMap[this.typeId] || tooltipTextMap[0] || { title: '', description: '' };
  }
}
