import { inject } from '@angular/core';
import { type ResolveFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../state/appState';
import { ClientSelectors } from '../state/clients/clients.selectors';
import { filter, first, map } from 'rxjs';
import { ClientType } from '../state/types';
import { AllInclusiveService } from '../services/all-inclusive.service';

interface Guide {
  showGuide: boolean;
  clientsWithoutCloudApiKey?: ClientType[];
  hasAnyClients?: boolean;
  hasAllClientsCloudApiKey?: boolean;
}

const waitForClientsToBeLoaded = (store: Store<AppState>) =>
  store.select(ClientSelectors.clientStateLoading).pipe(
    filter((loading) => !loading),
    first(),
  );

export const guideResolver: ResolveFn<Guide> = () => {
  const store = inject(Store<AppState>);
  const allInclusiveService = inject(AllInclusiveService);
  if (!allInclusiveService.isLoggedInWithAnyAllInclusiveService) {
    return {
      showGuide: false,
    };
  }
  return waitForClientsToBeLoaded(store).pipe(map(() => ({ showGuide: true })));
};
