import { ChangeDetectorRef, Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appStep]',
  standalone: true,
})
export class NewClientGuideStepDirective {
  @Input('appStep')
  set appStep({ currentStep, stepNumber }: { currentStep: number; stepNumber: number }) {
    this.showStep(currentStep, stepNumber);
  }

  // eslint-disable-next-line no-useless-constructor
  constructor(
    private view: ViewContainerRef,
    private template: TemplateRef<unknown>,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  private showStep(currentStep: number, componentStepNumber: number): void {
    this.view.clear();
    if (componentStepNumber === currentStep) {
      this.view.createEmbeddedView(this.template);

      // right now there is an issue with the corporate identity component
      // that is not updated when the step is shown, so we need to manually trigger the change detection
      this.changeDetectorRef.markForCheck();
    }
  }
}
