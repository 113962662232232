import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { BlFrontendButtonComponent } from '@app/core/wrappers/bl-frontend-button.component';
import { BystSharedModule } from '@app/shared/byst-shared.module';
import { ClientFormGroup, ClientGuideForm } from '../new-client-guide.types';

@Component({
  selector: 'app-step2-business-details',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, BlFrontendButtonComponent, BystSharedModule, ReactiveFormsModule],
  template: `
    <ng-container [formGroup]="form">
      <div class="flex flex-col">
        <label for="businessArea">Bransch</label>
        <input type="text" formControlName="businessArea" pInputText />
      </div>
      <div class="flex flex-col mt-3">
        <label for="registrationDate">Registreringsdatum</label>
        <app-calendar
          formControlName="registrationDate"
          appendTo="body"
          [defaultDate]="null"
          [minSelectableDate]="null">
        </app-calendar>
      </div>
      <div class="flex flex-col mt-3">
        <label for="description">Beskrivning av verksamheten</label>
        <textarea formControlName="description" pInputTextarea rows="6"></textarea>
      </div>
    </ng-container>
  `,
})
export class Step2BusinessDetailsComponent implements OnInit {
  @Input() formGroupName: keyof ClientGuideForm;

  form: FormGroup<ClientFormGroup>;

  // eslint-disable-next-line no-useless-constructor
  constructor(private rootFormGroup: FormGroupDirective) {}

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
  }
}
