import { createSelector } from '@ngrx/store';
import { INCLUDE_ARCHIVED_CLIENTS_LABEL } from '@app/shared/misc/constants';
import { ListSelectors } from '../list/list.selectors';
import { toSimpleClientTypeTransformer } from '../transformers/transformers';
import { ClientListFilter, ClientType } from '../types';
import { AppState } from '../appState';
import { TeamsSelectors } from '../teams/teams.selectors';
import { AuthSelectors } from '../auth/auth.selectors';
import { FirmSelectors } from '../firm/firm.selectors';
import { getSustainabilityReportingInfo } from '@app/shared/misc/getActivateOrDeactivateSustainabilityReportingInfo';

const clientsState = (state: AppState) => state.clients;

const allClients = createSelector(clientsState, (state) => state.clients);

const allSimpleClients = createSelector(clientsState, (state) =>
  state.clients.map(toSimpleClientTypeTransformer.transform),
);

const allActiveClients = createSelector(clientsState, (state) => state.clients.filter((client) => !client.archived));

const allActiveSimpleClients = createSelector(allActiveClients, (clients) =>
  clients.map(toSimpleClientTypeTransformer.transform),
);

const allListClients = ListSelectors.getItems<ClientType, ClientListFilter, object>();

const activateOrDeactivateSustainabilityReportingInfo = createSelector(
  allListClients,
  AuthSelectors.selectAuthUser,
  FirmSelectors.selectFirm,
  ({ items, filter, params }, user, firm) => ({
    items: items.map((client) => {
      const { canActivate, tooltip } = getSustainabilityReportingInfo({ user, client, firm });
      return {
        ...client,
        sustainabilityReportingDisabled: !canActivate,
        sustainabilityReportingTooltip: tooltip,
      };
    }),
    filter,
    params,
  }),
);

const allFilteredClients = createSelector(
  activateOrDeactivateSustainabilityReportingInfo,
  TeamsSelectors.selectRootTeam,
  ({ items, filter: { includeArchivedClients, responsibleUserId } }, rootTeam) => {
    const filteredClients = items.filter((client) => {
      const filterClientArchived = includeArchivedClients || !client.archived;
      const hasSelectedResponsibleUser = responsibleUserId !== 0;

      let filterSelectedUserResponsibleForClient = true;

      if (hasSelectedResponsibleUser) {
        filterSelectedUserResponsibleForClient = client.responsible && client.responsible.id === responsibleUserId;
      }

      return filterClientArchived && filterSelectedUserResponsibleForClient;
    });
    const archivedClients = items.filter(
      (client) => client.archived && (responsibleUserId !== 0 ? client.responsible?.id === responsibleUserId : true),
    );
    return { filteredClients, archivedClientsCount: archivedClients.length, rootTeam };
  },
);

const getClientById = (clientId: number) =>
  createSelector(clientsState, (state) => state.clients.find((c) => c.id === clientId));

const checkUniqueOrgNr = (orgNumber: string) =>
  createSelector(
    clientsState,
    (state) => !state.clients?.find((clientType) => clientType.corporateIdentity === orgNumber),
  );

const includeArchivedLabelSelector = createSelector(
  allFilteredClients,
  ({ archivedClientsCount }) => `${INCLUDE_ARCHIVED_CLIENTS_LABEL} (${archivedClientsCount})`,
);

const clientStateLoading = createSelector(clientsState, (state) => state.loading);
const clientStateDeleting = createSelector(clientsState, (state) => state.deleting);
const clientStateArchiving = createSelector(clientsState, (state) => state.archiving);
const clientStateActivating = createSelector(clientsState, (state) => state.activating);
const clientStateDeactivating = createSelector(clientsState, (state) => state.deactivating);

export const ClientSelectors = {
  allClients,
  allActiveClients,
  allSimpleClients,
  allActiveSimpleClients,
  allFilteredClients,
  getClientById,
  checkUniqueOrgNr,
  includeArchivedLabelSelector,
  clientStateLoading,
  clientStateDeleting,
  clientStateArchiving,
  clientStateActivating,
  clientStateDeactivating,
};
