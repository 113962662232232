import { ChangeDetectionStrategy, Component, computed, Signal, signal, ViewEncapsulation } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { FinancialYear } from '@app/core/entity/financialyear';
import { CreditInformationActions } from '@app/core/state/credit-information/credit-information.actions';
import { CreditInformationSelectors } from '@app/core/state/credit-information/credit-information.selectors';
import {
  getAccountPeriod,
  toClientTypeFromCreditInformationTransformer,
} from '@app/core/state/transformers/credit-information.transformers';
import { ClientType } from '@app/core/state/types';
import { ClientGuideData } from '@app/core/state/types/client-guide.types';
import { CreditInformationType } from '@app/core/state/types/credit-information.types';
import {
  DialogResult,
  FormDialogBaseComponent,
} from '@app/shared/components/form-dialog-base/form-dialog-base.component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { combineLatest, filter, map, withLatestFrom } from 'rxjs';
import { Step1BasicInformationComponent } from './steps/step1-basic-information.component';
import { Step2BusinessDetailsComponent } from './steps/step2-business-details.component';
import { Step3FinancialInformationComponent } from './steps/step3-financial-information.component';
import { CommonModule } from '@angular/common';
import { ClientGuideForm } from './new-client-guide.types';
import { BlFrontendButtonComponent } from '@app/core/wrappers/bl-frontend-button.component';
import { NewClientGuideFormService } from './new-client-guide-form.service';
import { OnboardingFinancialInformation } from '@app/core/services/onboarding.service.types';
import { NewClientGuideStepDirective } from './steps/step.directive';
import { ClientTypesSelectors } from '@app/core/state/clientTypes/clientTypes.selectors';

export interface Result extends DialogResult {
  client?: Partial<ClientType>;
  financialInformation?: OnboardingFinancialInformation;
}

const FIRST_STEP = 1;
const LAST_STEP = 3;
const CLIENT_FORM_STEPS = [1, 2];

@Component({
  templateUrl: './new-client-guide-dialog.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NewClientGuideStepDirective,
    BlFrontendButtonComponent,
    Step1BasicInformationComponent,
    Step2BusinessDetailsComponent,
    Step3FinancialInformationComponent,
  ],
  providers: [NewClientGuideFormService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      .new-client-guide-form input.ng-invalid.ng-touched {
        border-color: #e3342f;
      }
    `,
  ],
})
export class NewClientGuideDialogComponent extends FormDialogBaseComponent<ClientGuideData, Result, ClientGuideForm> {
  currentStep = signal(1);
  isFirstStep = computed(() => this.currentStep() === FIRST_STEP);
  isLastStep = computed(() => this.currentStep() === LAST_STEP);
  isValid: Signal<boolean>;

  constructor(
    protected override ref: DynamicDialogRef,
    protected override config: DynamicDialogConfig<ClientGuideData>,
    private formService: NewClientGuideFormService,
  ) {
    super(ref, config);
    this.form = this.formService.createForm();

    const stepAndStatusChanges$ = this.getStepAndFormStatusChangesObservable();
    this.isValid = toSignal(stepAndStatusChanges$);
  }

  protected static override getDialogConfig(): Omit<DynamicDialogConfig<ClientGuideData>, 'data'> {
    return {
      header: 'Guide för att skapa ny klient',
      modal: true,
      closable: true,
      closeOnEscape: true,
      width: '700px',
      draggable: false,
      resizable: false,
      position: 'center',
    };
  }

  protected override onInitialized = (): void => {
    this.listenForCreditInformationChanges();
  };

  previous() {
    this.currentStep.update((step) => step - 1);
  }

  next() {
    this.currentStep.update((step) => step + 1);
  }

  onSubmit() {
    if (!this.formService.isFormValid()) {
      return;
    }
    this.close(this.formService.getFormValue());
  }

  onClose() {
    this.close(null);
  }

  onLoadCreditInformation(corporateIdentity: string) {
    this.store.dispatch(CreditInformationActions.load({ corporateIdentity }));
  }

  private getStepAndFormStatusChangesObservable() {
    return combineLatest([toObservable(this.currentStep), this.form.statusChanges]).pipe(map(() => this.isFormValid()));
  }

  private isFormValid(): boolean {
    // Validate only the client information if we are on the first or second step
    const isClientInformationStep = CLIENT_FORM_STEPS.includes(this.currentStep());

    if (isClientInformationStep) {
      return this.formService.isClientInformationValid();
    }
    return this.formService.isFormValid();
  }

  private listenForCreditInformationChanges(): void {
    this.store
      .select(CreditInformationSelectors.selectCreditInformation)
      .pipe(
        map((creditInformation) =>
          creditInformation ? this.transformCreditInformationToModel(creditInformation) : null,
        ),
        filter((client) => Boolean(client)),
        withLatestFrom(this.store.select(ClientTypesSelectors.all)),
        map(([client, clientTypes]) => {
          // Use the client type from the store so the dropdown can be preselected
          const clientType = clientTypes.find((type) => type.name === client.type?.name);
          return { ...client, type: clientType };
        }),
        this.takeUntilDestroyed(),
      )
      .subscribe({
        next: (client) => this.patchFormWithLoadedClientData(client),
      });
  }

  private patchFormWithLoadedClientData(client: Partial<ClientType> | null): void {
    if (!client) {
      return;
    }

    this.formService.patchClientData(client);
    this.formService.patchFinancialYear(client.financialYears[0].span);
  }

  private transformCreditInformationToModel(creditInformation: CreditInformationType): Partial<ClientType> {
    const client = toClientTypeFromCreditInformationTransformer.transform(creditInformation);

    // get default accounting period
    const accountPeriod = getAccountPeriod(creditInformation);
    if (accountPeriod) {
      const financialYear = new FinancialYear();
      financialYear.span = accountPeriod;
      client.financialYears = [financialYear];
    }

    return client;
  }
}
