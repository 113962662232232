<form [formGroup]="form" (ngSubmit)="onSubmit()" pFocusTrap class="new-client-guide-form">
  <div class="flex flex-col w-full py-2 flex-wrap">
    <app-step1-basic-information
      *appStep="{ currentStep: currentStep(), stepNumber: 1 }"
      formGroupName="client"
      (loadCreditInformation)="onLoadCreditInformation($event)"></app-step1-basic-information>

    <app-step2-business-details
      *appStep="{ currentStep: currentStep(), stepNumber: 2 }"
      formGroupName="client"></app-step2-business-details>

    <app-step3-financial-information
      *appStep="{ currentStep: currentStep(), stepNumber: 3 }"
      formGroupName="financialInformation"></app-step3-financial-information>

    <div class="flex justify-between mt-10">
      <bl-frontend-button *ngIf="!isFirstStep()" text="<< Föregående" (onClick)="previous()"></bl-frontend-button>
      <div></div>
      <div class="flex">
        <div class="mr-3">
          <bl-frontend-button variant="secondary" text="Avbryt" (onClick)="onClose()"></bl-frontend-button>
        </div>
        <bl-frontend-button
          *ngIf="!isLastStep()"
          text="Nästa >>"
          [disabled]="!isValid()"
          (onClick)="next()"></bl-frontend-button>
        <bl-frontend-button
          *ngIf="isLastStep()"
          type="submit"
          text="Skapa"
          [disabled]="!isValid()"
          (onClick)="onSubmit()"></bl-frontend-button>
      </div>
    </div>
  </div>
</form>
