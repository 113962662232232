import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { BlaCompany } from '../entity/bla-company';
import { Client } from '../entity/client';
import { BLService } from './bl.service';
import { HttpAuthorizationHeader } from '../misc/httpauthorizationheader';
import { environment } from 'src/environments/environment';
import { CloudDbData } from '../entity/cloud-db-data';
import { Type } from '../entity/type';
import { ClientService } from './clients.service';
import { Store } from '@ngrx/store';
import { AppState } from '../state/appState';
import { ToastActions } from '../state/toast/toast.actions';
import { CLIENT_TYPE_LIMITED_COMPANY } from '@app/shared/misc/constants';
import { ErrorMessageType, catchErrorAndShowMessage } from '../misc/errorHandlers';
import { CollaborationPackageParams, CollaborationPackageResponse } from '../state/types/collaboration-package.types';

@Injectable({
  providedIn: 'root',
})
export class CloudCompanyService extends BLService {
  private options = new HttpAuthorizationHeader('application/json');
  private RESOURCE_ENDPOINT = 'cloudcompany';
  private url = environment.serverUrl + this.RESOURCE_ENDPOINT;

  constructor(private http: HttpClient, private clientService: ClientService, store: Store<AppState>) {
    super(store);
    this.useCache();
  }

  getCloudCompanies(useDeepClientSearch = true, skipCache = false): Observable<BlaCompany[]> {
    if (!this.hasCacheExpired() && skipCache === false) {
      return this.getCacheObservable<BlaCompany[]>();
    }

    const param = useDeepClientSearch ? '?deep=true' : '';

    return this.http.get<BlaCompany[]>(this.url + param, this.options.getAuthorizationHeaderWithEmptyBody()).pipe(
      tap((result) => skipCache || this.updateCache(result)), // don't update cache if "skipCache" is true
      this.catchErrorAndShowMessage(),
    );
  }

  getCollaborationCloudCompanies({
    responsibleId,
  }: CollaborationPackageParams): Observable<CollaborationPackageResponse> {
    const params = responsibleId ? `?userId=${responsibleId}` : '';

    return this.http
      .get<CollaborationPackageResponse>(
        `${this.url}/list${params}`,
        this.options.getAuthorizationHeaderWithEmptyBody(),
      )
      .pipe(this.catchErrorAndShowMessage());
  }

  getCloudCompanyEmail({ clientId, cloudApiKey }: { clientId: number; cloudApiKey: string }): Observable<string> {
    return this.http
      .get<{ email: string }>(
        `${this.url}/email?clientId=${clientId || ''}&cloudApiKey=${cloudApiKey || ''}`,
        this.options.getAuthorizationHeaderWithEmptyBody(),
      )
      .pipe(
        map((result) => result?.email || null),
        this.catchErrorAndShowMessage(),
      );
  }

  updateCloudCompanyInformationByClientId(clientId: number) {
    const afterErrorMessageProcessor = ({ summary, detail }: ErrorMessageType) => ({
      summary,
      detail: detail.replace('{0}', 'uppdateras'),
    });

    return this.http
      .put<Client>(`${this.url}/${clientId}`, null, this.options.getAuthorizationHeader())
      .pipe(catchErrorAndShowMessage({ store: this.store, afterErrorMessageProcessor }));
  }

  exportClientToCloud(data: CloudDbData): Observable<{ cloudApiKey: string }> {
    const afterErrorMessageProcessor = ({ summary, detail }: ErrorMessageType) => ({
      summary,
      detail: detail.replace('{0}', 'skapas'),
    });

    return this.http
      .post<{ cloudApiKey: string }>(`${this.url}/export`, data, this.options.getAuthorizationHeader())
      .pipe(
        tap(() =>
          this.store.dispatch(
            ToastActions.showInfoMessage({ summary: 'Sparat', detail: 'Ny molndatabas har skapats för klienten' }),
          ),
        ),
        catchErrorAndShowMessage({ store: this.store, afterErrorMessageProcessor }),
      );
  }

  importCloudCompaniesToByst(
    selectedCloudCompanies: BlaCompany[],
  ): Observable<{ success: boolean; createdClients: Client[]; error?: string }> {
    return this.clientService.getClientsTypes().pipe(
      map((allCompanyTypes) =>
        selectedCloudCompanies.map((company) => this.mapCompanyToClient(company, allCompanyTypes)),
      ),
      switchMap((clients: Client[]) =>
        this.http.post<Client[]>(`${this.url}/import`, clients, this.options.getAuthorizationHeader()),
      ),
      tap(() =>
        this.store.dispatch(
          ToastActions.showInfoMessage({ summary: 'Sparat', detail: 'Klienten är importerad till Byråstöd' }),
        ),
      ),
      this.catchErrorAndShowMessage(),
    );
  }

  archiveCloudCompany(cloudApiKey: string, amountOfYears: number) {
    return this.http
      .put(`${this.url}/archive/${cloudApiKey}`, { amountOfYears }, this.options.getAuthorizationHeader())
      .pipe(this.catchErrorAndShowMessage());
  }

  unarchiveCloudCompany(cloudApiKey: string, data: { clientName: string; socialSecurityNumber: string }) {
    return this.http
      .put(`${this.url}/unarchive/${cloudApiKey}`, data, this.options.getAuthorizationHeader())
      .pipe(this.catchErrorAndShowMessage());
  }

  deleteCloudCompany(cloudApiKey: string) {
    return this.http
      .delete(`${this.url}/delete/${cloudApiKey}`, this.options.getAuthorizationHeader())
      .pipe(this.catchErrorAndShowMessage());
  }

  private mapCompanyToClient(company: BlaCompany, allCompanyTypes: Type[]) {
    const client = new Client(company.name);
    client.id = null;
    client.cloudApiKey = company.publicKey;
    client.corporateIdentity = company.orgNumber;
    client.type = this.getCompanyType(allCompanyTypes, company.companyType);
    client.email = company.email;
    client.phone = company.phone;
    client.address = company.box;
    client.zipCode = company.zip;
    client.city = company.city;
    client.residence = company.domicile;
    client.country = company.country;
    client.webpage = company.web;
    return client;
  }

  private getCompanyType(allCompanyTypes: Type[], cloudCompanyType: number) {
    // key = cloud company type number
    // value = byst name of the type
    const mapping: Record<number, string> = {
      1: CLIENT_TYPE_LIMITED_COMPANY,
      2: 'PARTNERSHIP',
      3: 'SOLE_PROPRIETORSHIP',
      4: CLIENT_TYPE_LIMITED_COMPANY,
      5: 'NON_PROFIT_ASSOCIATION',
    };

    const name = mapping[cloudCompanyType];

    return allCompanyTypes.find((type) => type.name === name);

    // AB = 1;
    // HB_KB = 2;
    // ENSKILD_FIRMA = 3;
    // EKONOMISK_FORENING = 4;
    // IDEELL_FORENING = 5;

    // PERSON('Privatperson'),
    // SOLE_PROPRIETORSHIP('Enskild firma'),
    // LIMITED_COMPANY('Aktiebolag/Ekonomisk förening'),
    // PARTNERSHIP('Handelsbolag/Kommanditbolag'),
    // NON_PROFIT_ASSOCIATION('Ideell förening/Stiftelse');
  }
}
